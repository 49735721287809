@import "color";

.c-float-button {
  width: auto;
  position: fixed;
  bottom: 39px;
  right: 16px;
  z-index: 99;
  .content-float-button {
    padding: 14px 16px 14px 16px;
    border-radius: 24px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: $black;
    display: flex;
    align-items: center;
    &__icon {
      margin-right: 5px;
      margin-left: -3px;
      background-color: $white;
      margin-top: -2px;
    }
    &__text {
      font-family: Pretendard;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.19;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
      margin: 0;
    }
  }
}