.good-list-page {
	background-color: #f6f6f6;
	padding: 0px !important;
	&__header{
		background-color: #f6f6f6;
		//position: fixed;
    width: 100%;
    z-index: 9998;
		&__top{
			background-color: #fff;
			border-bottom-left-radius: 12px;
			border-bottom-right-radius: 12px;
			.good-search-group{
				position: relative;
				.goods-search{
					padding: 0px 14px 6px 14px;
					.c-form-control{
						.c-input-wrapper{
							input[name="goods_search"]{
								border: solid 2px #ff773a;
								outline: none;
								padding-right: 46px;

								font-family: Pretendard;
								font-size: 15px;
								font-weight: normal;
								font-stretch: normal;
								font-style: normal;
								line-height: 1.2;
								letter-spacing: normal;
								text-align: left;
								color: #000;
								box-sizing: border-box;
								&:focus{
									border: solid 2px #ff773a;
								}
								&::-webkit-input-placeholder { /* Edge */
									font-family: Pretendard;
									font-size: 15px;
									text-align: left;
									color: #000;
								}
								
								&:-ms-input-placeholder { /* Internet Explorer 10-11 */
									font-family: Pretendard;
									font-size: 15px;
									text-align: left;
									color: #000;
								}
								
								&::placeholder {
									font-family: Pretendard;
									font-size: 15px;
									text-align: left;
									color: #000;
								}
							}
						}
					}
				}
				.search-icon{
					position: absolute;
					top: 19px;
    			right: 28px;
					background-color: #fff;
					border: none;
				}
				.autocomplete-box{
					width: 100%;
					position: absolute;
					border-radius: 6px;
					box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.05);
					border: solid 0.5px #ff773a;
					background-color: #fbfbfb;
					z-index: 9999;
					max-height: 250px;
					overflow: hidden;
					visibility: visible;
					top: 60px;
					width: calc(100% - 28px);
    			left: 14px;

					&__child{
						height: 100%;
						overflow-y: scroll;
						max-height: 180px;
						ul{
							padding: 0;
							margin: 0;
							li{
								list-style: none;
								display: block;
								padding: 10px 16px;
								border-bottom: solid 1px #eee;
								width: 100%;
								text-align: left;

								max-width: calc(100% - 20px);
								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;
							}
						}
					}
				}
			}
		}
		.good-filter{
			// display: flex;
			// justify-content: end;
			text-align: end;
			padding: 21px 16px 8px 16px;
			z-index: 9997;
			background-color: #fff;
			position: relative;
			&__title{
				label{
					font-family: Pretendard;
					font-size: 12px;
					font-weight: normal;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.17;
					letter-spacing: normal;
					text-align: left;
					color: #8b8b8b;
					margin-right: 9px;
				}
				img{
					width: 12px;
					height: 6px;
				}
			}
			&__group{
				position: absolute;
				list-style: none;
				z-index: 999;
				background-color: #fff;
				top: 45px;
				right: 15px;
				padding: 12px 25px;
				border-radius: 6px;
				border: solid 1px #707070;
				&__item{
					label{
						font-family: Pretendard;
						font-size: 12px;
						font-weight: normal;
						font-stretch: normal;
						font-style: normal;
						line-height: 1.17;
						letter-spacing: normal;
						text-align: left;
						color: #8b8b8b;
					}
					&.active{
						label{
							font-weight: 600;
						}
					}
				}
				&.hide{
					display: none;
				}
				&.show{
					display: block;
				}
			}
		}
	}
	&__content{
		background-color: #fff;
		padding: 0 0 0 0;
		.good-list-loading{
			display: flex;
			justify-content: space-between;
			padding: 16px;
			height: 100vh;

			&__item{
				width: calc(50% - 28px);
				// padding-bottom: 100px;
				.loading-img{
					.react-loading-skeleton{
						border-radius: 6px;
						margin-bottom: 6px;
					}
				}
			}
		}
		.list-product-order{
			padding: 0 14px;
			padding-bottom: 30px;
			overflow-y: scroll;
			&.good-list-loading{
				padding: 0px 0px 20px 0px; 
			}
			.product-list-item {

				&__content {
					display: block;
					.box-content {
						display: block;
					}
				}
				&.type-big{
					.product-list-item__product {
						.image-product {
							min-width: auto;
						}
					}
				}
			}
		}
		.good-list-empty{
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100vw;
  		background-color: #fff;
			.box-content{
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				&__text{
					font-family: Pretendard;
					font-size: 23px;
					font-weight: 600;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.22;
					letter-spacing: normal;
					text-align: left;
					color: #000;
				}
			}
		}
	}
}


//fixbub 41200
.good-list-page {
	&__header {
		&__top {
			.good-search-group {
				padding-bottom: 16px;
				form {
					margin: 0px 14px 0px 14px;
					border: solid 2px #ff773a;
					border-radius: 6px;
					position: relative;
				}
				.goods-search {
					padding: 0;
					width: calc(100% - 45px);
					.c-form-control .c-input-wrapper input[name="goods_search"] {
						border: none !important;
						margin: 0;
						padding-right: 0px;
					}
				}
				.search-icon {
					position: absolute;
					top: 50%;
					right: 0;
					background-color: #fff;
					border: none;
					top: 50%;
					transform: translateY(-50%);
					padding-left: 0;
					img {
						margin-right: 5px;
					}
				}
			}
		}
	}
}

//end bug 41200