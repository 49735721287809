.consult-page{
  padding: 54px 0 0 0 !important;
  background-color: #ebebeb;

  .box-empty {
    background: white;
    margin-top: 10px;
  }

  header {
    background: #FFFFFF;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
    z-index: 999;
  }

  .floatCTA{
    padding: 14px 16px;
    border-radius: 24px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #000;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    border: 0;
    position: fixed;
    right: 16px;
    bottom: 40px;
  }
  .load-data {
    overflow-y: hidden;
  }
  main, .list {
    margin-top: 10px;
    background: #FFFFFF;
    padding: 0px;
    padding-top: 0px;
    border-top: 14px solid #FFFFFF;
    overflow-y: scroll;
    .c-empty-icon{
      margin-top: 97px;
    }
    .consult-item{
      margin-bottom: 14px;
      margin-left: 16px;
      margin-right: 16px;
      padding: 14px 0px;
      border-top: 1px solid #eee;
      &:last-child {
        border-bottom: 1px solid #eee;
      }
      .time{
        font-family: Pretendard;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: normal;
        color: #8b8b8b;
      }
      .title{
        margin-top: 12px;
        margin-bottom: 8px;
        .status{
          display: inline-block;
          padding: 4px 7px;
          border-radius: 10px;
          border: solid 1px #35a1e9;
          font-family: Pretendard;
          font-size: 10px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          letter-spacing: normal;
          text-align: center;
          color: #35a1e9;
          &.pending{

            color: #f24b38;
            border: solid 1px #f24b38;
          }
        }
        .subject{
          display: inline-block;
          font-family: Pretendard;
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.14;
          letter-spacing: normal;
          text-align: left;
          color: #000;
          margin-left: 9px;
        }
      }
      .description{
        font-family: Pretendard;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: normal;
        color: #000;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  main{
    padding: 16px;
    border-top: 0;
    padding-bottom: 100px;
    //position: relative;

    .c-form-control input[type='text'], .c-form-control input[type='number'], .c-form-control input[type='phone'], .c-form-control input[type='password'] {
      height: 60px;
      font-family: Pretendard;
      font-size: 14px;
      margin: 10px 0;
      padding: 15px 16px;
      border-radius: 6px;
      border: solid 1px #e2e2e2;
      width: 100%;
      background: #fbfbfb;
    }
    .c-form-control .c-dropdown-wrapper .c-dropdown-input .icon {
      position: absolute;
      top: 30px;
      right: 20px;
    }
    .c-form-control textarea{
      background: #fbfbfb;
      padding: 15px 16px;
      display: block;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 1px; /* Adjust as needed */
      }
      &::-webkit-scrollbar-thumb {
        background-color: #858585; /* Adjust as needed */
      }
    }
    .count-chacter {
      display: block;
      justify-content: end;
      margin-top: -5px;
      width: 100%;
      text-align: right;
      label {
        font-family: Pretendard;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: normal;
        text-align: right;
        color: #858585;
      }
    }
  }
  .c-form-control {
    .c-dropdown-wrapper {
      .c-dropdown-menu__list {
        ul {
          li {
            font-family: Pretendard;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: -0.28px;
            text-align: left;
            color: #686868;
          }
        }
      }
    }
  }
  .disabled-add {
    pointer-events: none;
    .picker {
      background: #ebebeb;
    }
  }
}

.cancel-dialog{
  text-align: center;
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.3px;
  color: #686868;
  .c-dialog-body {
    padding: 0 30px 0px 30px;
    min-height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    span{
      display: inline;
    }
  }
}

.consult-detail {
  padding: 54px 0 0 0 !important;
  background-color: #ebebeb;


  header {
    background: #FFFFFF;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 99;
  }
  main {
    margin-top: 10px;
    padding: 16px 0;
    border-top: 0;
    background: #FFF;

    .question{
      padding: 0px 16px 0;
      background-color: #fbfbfb;
      .content-title {
        margin-bottom: 17px;
        span {
          font-family: Pretendard;
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.19;
          letter-spacing: -0.16px;
          text-align: left;
          color: #000;
        }
      }
      .question-info {
        // border-top: 1px solid #eee;
        padding-top: 16px;
        display: flex;
        align-items: center;
        margin-bottom: 12px;
      }
      .time{
        font-family: Pretendard;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: normal;
        color: #8b8b8b;
        margin-bottom: 11px;
        border-bottom: 1px solid #d7d7d7;
        padding-bottom: 5px;
      }
      .category{
        font-family: Pretendard;
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.15;
        letter-spacing: -0.39px;
        text-align: left;
        color: #000;
        // padding-bottom: 18px;
        // border-bottom: 1px solid #d7d7d7;
      }
      .status {
        display: inline-block;
        padding: 4px 7px;
        border-radius: 10px;
        border: solid 1px #35a1e9;
        font-family: Pretendard;
        font-size: 10px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: center;
        color: #35a1e9;
        display: inline-block;
        margin-right: 5px;
        &.pending {

          color: #f24b38;
          border: solid 1px #f24b38;
        }
      }
      .attachments{
        padding: 0px 0px 25px;
        // display: flex;
        // flex-wrap: wrap;
        overflow-x: auto;
        .attachment-item{
          display: inline-block;
          // margin: 0 4.5px 7px 4.5px;
          width: calc(100% / 4 - 9px);
          height: 165px;
          border-radius: 7px;
          overflow: hidden;
          width: 100%;
          max-width: 360px;
          min-width: 100%;
          &:nth-child(4n) {
            margin-right: 0;
          }
          img{
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
        .carousel-status {
          padding: 4px 9px 4px 10px;
          // opacity: 0.6;
          border-radius: 12px;
          background-color:#03030399;
          font-family: Pretendard;
          font-size: 13px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.15;
          letter-spacing: normal;
          text-align: center;
          color: #fff;
          margin: 0;
          right: 13px;
          top: 11px;
          min-width: 45px;
        }
        .control-arrow {
          background-repeat: no-repeat;
          height: 33px;
          min-height: auto;
          top: 50%;
          transform: translateY(-50%);
          width: 33px;
          opacity: 1;
          &.control-next {
            right: 10px;
          }
          &.control-prev {
            left: 10px;
          }
          &::before {
            display: none;
          }
        }
        &.has-one {
          .carousel-status {
            display: none;
          }
        }
      }
      .content{
        font-family: Pretendard;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: left;
        color: #686868;
        margin-bottom: 26px;
      }
      .action{
        text-align: right;
        padding-bottom: 25px;
        border-bottom: 1px solid #eee;
        span{
          font-family: Pretendard;
          font-size: 14px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.14;
          letter-spacing: normal;
          text-align: center;
          color: #000;
          padding: 0 5px;
          &:last-child{
            border-left: 1px solid #ebebeb;
          }
        }
      }
    }
  }
  .delete-dialog{
    text-align: center;
    font-family: Pretendard;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -0.3px;
    color: #686868;
    .c-dialog-body {
      padding: 0 30px 0px 30px;
      min-height: 110px;
      display: flex;
      align-items: center;
      justify-content: center;
      span{
        display: inline;
      }
    }
  }
  .content-answer {
    border-bottom: 1px solid #eee;
    padding-bottom: 37px;
    h4 {
      font-family: Pretendard;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      color: #35a1e9;
      margin-bottom: 8px;
    }
    .text-anser {
      margin-bottom: 14px;
      font-family: Pretendard;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      color: #686868;
    }
    .time-answer {
      font-family: Pretendard;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.17;
      letter-spacing: normal;
      text-align: center;
      color: #8b8b8b;
      
    }
    .text-anser {
      p,span {
        word-break: break-all;
      }
      img {
        display: inline-block;
        margin: 0 4.5px 7px 4.5px;
        width: calc(100% / 4 - 9px);
        height: 72px;
        border-radius: 7px;
        overflow: hidden;
        width: 100%;
        height: 165px;
        object-fit: cover;
        text-align: center;
        margin: auto;
        display: block;
        max-width: 360px;
      }
    }
    .contact-company {
      margin-bottom: 20px;
      strong {
        width: 12px;
        height: 12px;
        border-radius: 2px;
        background-color: #686868;
        display: inline-block;
        margin-right: 3px;
      }
      span {
        font-family: Pretendard;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: left;
        color: #686868;
      }
    }
  }
  .btn-back-page {
    display: flex;
    text-align: center;
    justify-content: center;
    margin-top: 16px;
    padding-bottom: 20px;
    button {
      width: calc(100% - 28px);
      height: 54px;
      border-radius: 6px;
      font-family: Pretendard;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.19;
      letter-spacing: normal;
      text-align: center;
      color: #ff773a;
      border: solid 1px #ff773a;
      background-color: white;
    }
  }
}

.preview{
  position: fixed;
  z-index: 999999999;
  inset: 0 0 0 0;
  background: rgba(0, 0, 0, .8);
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  .overlay{

  }

  .image{
    position: relative;
    width: 100%;
    height: 80%;
    margin-top: 50%;
    translate-Y: -50%;

    img{
      //object-fit: contain;
      max-width: 100%;
      max-height: 80%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);

    }
    .close-btn{
      position: absolute;
      right: 10px;
      top: -50px;
      height: 30px;
      width: 30px;
    }
  }
}