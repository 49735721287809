@import "./src/core/assets/scss/color";

.settings-page {
  background:  #f6f6f6;
  padding: 0 !important;
  header {
    margin: 0 0;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
    background: $white;
  }
  &__main {
    margin-top: 10px;
    background: $white;
    //min-height: 90vh;
    &.empty-main {
      background:  #f6f6f6;
    }
    &__content{

      .box-content {
        //box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
          &__type {
            padding: 15px 16px 14px;
            border-bottom: solid 1px #efefef;
            h4 {
              font-family: Pretendard;
              font-size: 18px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.17;
              letter-spacing: normal;
              text-align: left;
              color: #ff773a;
            }
          }
          .c-accodition {
            box-shadow: none;
            border-bottom: solid 1px #efefef;
            &__answer {
              &.open {
                font-family: Pretendard;
                font-size: 13px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.54;
                letter-spacing: normal;
                text-align: left;
                color: #6e6e6e;
              }
            }
          }
      }

    }
  }

}