@import "./src/core/assets/scss/color";

.forgot-pw-page {
  background: $white;
  .c-dialog-body {
    display: flex;
  }
  header {
    margin: 0 -16px 0;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
  }
  &__main {
    margin-top: 30px;
    &__form {
      .c-form-control {
        input:disabled {
          background: #eeeeee;
        }
      }
      .form-verify-code {
        margin-bottom: 20px;
        .c-input-button-group {
          input,button {
            margin-bottom: 0;
          }
          &.verify {
            button {
              &:hover {
                background-color: #ff773a;
              }
            }
          }
        }
        .group-input-phone {
          button {
            &:disabled {
              color: #ababab;
              background-color: white;
              border: solid 1px #ababab;
            }
          }
        }
        &.disable {
          .c-input-button-group {
            &.verify {
              button {
                background-color: #cecece;
                pointer-events: none;
              }
              .timer {
                display: none;
              }
            }
            input {
              pointer-events: none;
              background-color: #eeeeee;
            }
          }
          .group-input-phone {
            button {
              pointer-events: none;
              color: #ababab;
              background-color: white;
              border: solid 1px #ababab;
            }
          }
        }
        
      }
      .form-password {
        .c-form-control {
          margin-bottom: 10px;
        }
      }
    }
  }
  .dialog-custom-button {
    .c-dialog-body {
      span {
        font-family: Pretendard;
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: -0.3px;
        text-align: center;
        color: #686868;
        display: inline-block;
        width: 100%;
      }
    }
  }
}