.order-cancel-page {
  background-color: #f6f6f6;
  padding: 0px !important;
  &__custom-header {
    position: fixed;
    width: 100%;
    top: 0px;
    z-index: 9999;
    background-color: #fff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
  }
  &__content{
    padding-top: 64px;
    .form-cancel{
      .step1{
        background-color: #fff;
        padding: 16px;
        height: calc(100vh - 64px);
       .reason-cancel{
        label{
          font-family: Pretendard;
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.19;
          letter-spacing: -0.32px;
          text-align: left;
          color: #000;
        }
        .option-cancel{
          margin: 18px 0px;
          &__item{
            display: flex;
            justify-content: left;
            align-items: center;
            margin-bottom: 10px;
            span{
              font-family: Pretendard;
              font-size: 14px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              letter-spacing: normal;
              text-align: center;
              color: #8b8b8b;
            }
            .c-form-control{
              display: flex;
              align-items: center;
              .reason-content{
                font-family: Pretendard;
                font-size: 14px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.14;
                letter-spacing: normal;
                text-align: center;
                color: #8b8b8b;
                
                &--checked{
                  font-weight: 500;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.14;
                  letter-spacing: normal;
                  text-align: center;
                  color: #000;
                }
              }
            }
          }
        }
       } 
       .reason-detail{
        .title{
          font-family: Pretendard;
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.19;
          letter-spacing: -0.32px;
          text-align: left;
          color: #000;
          span{
            color: red;
          }
        }
        .c-form-control{
          textarea{
            padding: 16px 20px;
            border-radius: 6px;
            border: solid 0.5px #e2e2e2;
            background-color: #fff;
            &:disabled{
              background-color: #fbfbfb;
            }
          }
        }
       }
       .refund-account{
        padding-bottom: 130px;
        &__title{
          font-family: Pretendard;
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: -0.32px;
          text-align: left;
          color: #000;
        }
        .account-info{
          label{
            font-family: Pretendard;
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            color: #000;
            span{
              color: #f10000;
              margin-left: 5px;
            }
          }
          .dropdown-bank-custom{
            position: relative;
          }
          .icon-dropdown{
            position: absolute;
            top: 26px;
            right: 18px;
          }
        }
       }
      }
      .step2{
        .cart-list{
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
          background-color: #fff;
          .title{
            padding: 21px 16px 10px 16px;
            border-bottom: solid 1px #e2e2e2;
            label{
              font-family: Pretendard;
              font-size: 18px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.11;
              letter-spacing: normal;
              text-align: left;
              color: #000;
              padding: 0;
              margin: 0;
              display: flex;
              .product-name{
                max-width: calc(100% - 75px);
                text-align: right;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-right: 3px;
                text-align: left;
              }
            }
          }
          .list{
            padding: 0px 16px;
            .cart-item{
              .product-list-item{
                &:first-child{
                  padding-top: 15px;
                }
                &:last-child{
                  border-bottom: none;
                }
                .box-content{
                  width: 100%;
                  .product-list-item__product{
                    width: 100%;
                    ///////
                    /// 
                    /// 
                    /// 
                    .info-product{
                      width: calc(100% - 80px);
                      position: relative;
                      .info-product__name{
                        margin-bottom: 17px;
                        .product {
                          display: flex;
                          flex-direction: column;
                          max-height: 4em;
                          overflow: hidden;
                          .product-info {
                            // display: flex;
                            // flex-wrap: nowrap;
                            // justify-content: left;
                            // align-items: flex-end;
                            .product-name {
                              // overflow: hidden;
                              // text-overflow: ellipsis;
                              // display: -webkit-box;
                              // -webkit-box-orient: vertical;
                              // -webkit-line-clamp: 2;
                            }
                            .quantity {
                              margin-left: 5px; 
                              white-space: nowrap;
                            }
                          }
                        }
                      }
                      .info-product__price {
                        // position: absolute;
                        // bottom: 0;
                        // left: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .reason-cancel{
          margin-top: 10px;
          padding: 16px;
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
          background-color: #fff;
          .content{
            label{
              font-family: Pretendard;
              font-size: 16px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.19;
              letter-spacing: normal;
              text-align: left;
              color: #000;
              margin-bottom: 10px;
              .reason-content{
                font-family: Pretendard;
                font-size: 14px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.14;
                letter-spacing: normal;
                text-align: left;
                color: #8b8b8b;
                white-space-collapse: break-spaces;
                word-break: break-all;
                span{
                  font-family: Pretendard;
                  font-size: 12px;
                  font-weight: 500;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.17;
                  letter-spacing: normal;
                  text-align: left;
                  color: #000;
                  .three-dot{
                    color: #8b8b8b;
                  }
                }
                .content-more{
                  word-break: break-word;
                  word-wrap: break-word;
                }
              }
            }
            p{
              font-family: Pretendard;
              font-size: 14px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.14;
              letter-spacing: normal;
              text-align: left;
              color: #8b8b8b;
              margin: 0;
              padding: 0;
              white-space-collapse: break-spaces;
              word-break: break-all;
            }
            .title-left{
              display: block;
            }
          }
        }
        .order-cancel-info{
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
          background-color: #fff;
          margin-top: 10px;
          .content{
            padding: 15px 16px;
            label{
              font-family: Pretendard;
              font-size: 16px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.19;
              letter-spacing: normal;
              text-align: left;
              color: #000;
              margin-bottom: 18px;
            }
            .item{
              display: flex;
              justify-content: left;

              font-family: Pretendard;
              font-size: 14px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.14;
              letter-spacing: normal;
              text-align: left;
              color: #000;
              margin-bottom: 4px;
              &__left{
                padding-right: 4px;
              }
            }
          }
        }
      }
      .button-group-cancel{
        &--step1{
          position: fixed;
          bottom: 0px;
          width: 100%;
          left: 0px;
          padding: 37px 0px;
          background-color: #fff;
          .item{
            padding: 0px 16px;
            display: flex;
            &__left{
              padding: 0px 5px 0px 0px;
              margin: 0px;
              width: 100%;
              .d-grid{
                button{
                  border-radius: 6px;
                  background-color: #ebebeb;
                  border: none;

                  font-family: Pretendard;
                  font-size: 16px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  letter-spacing: normal;
                  text-align: center;
                  color: #000;
                }
              }
            }
            &__right{
              width: 100%;

              padding: 0px 0px 0px 5px;
              margin: 0px;
            }
          }
        }
        &--step2{
          background-color: #fff;
          padding-bottom: 80px;

          .item{
            padding: 0px 16px;
            display: flex;
            &__left{
              padding: 0px 5px 0px 0px;
              margin: 0px;
              width: 100%;
              .d-grid{
                button{
                  border-radius: 6px;
                  background-color: #ff773a;
                  border: none;
                  font-family: Pretendard;
                  font-size: 16px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  letter-spacing: normal;
                  text-align: center;
                  color: #fff;
                }
              }
            }
            &__right{
              width: 100%;
              padding: 0px 0px 0px 5px;
              margin: 0px;
              .d-grid{
                button{
                  border: solid 1px #ff773a;
                  border-radius: 6px;
                  background-color: #fff;
                  font-family: Pretendard;
                  font-size: 16px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  letter-spacing: normal;
                  text-align: center;
                  color: #ff773a;
                }
              }
            }
          }
        }

      }
    }
  }
  .cancel-order-dialog{
    .c-dialog-body{
      display: flex;
      justify-content: center;
      align-items: center;
      div{
        font-family: Pretendard;
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: -0.3px;
        text-align: center;
        color: #686868;
      }
    }
    .c-dialog-footer{
      button.submit{
        color: #ffffff !important;
      }
    }
  }
}
