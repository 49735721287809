@import "color";

.c-list-item {


    &:first-child {
        border-top: 1px solid rgba(0, 0, 0, 0.06);
    }
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    &__content {
        display: flex;
        align-items: center;
        padding: 16px;
        justify-content: space-between;
        &.has-time {
            padding-bottom: 6px;
        }
        &__text {
            width: calc(100% - 15px);
            .item-title {
                font-family: Pretendard;
                font-size: 15px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.14;
                letter-spacing: normal;
                text-align: left;
                color: $black;
                margin-bottom: 0;
            }
            .item-time {
                font-family: Pretendard;
                font-size: 11px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.18;
                letter-spacing: normal;
                text-align: left;
                color: #c9c7c7;
                display: block;
                margin-top: 10px;
            }
        }

        &__icon {
            &.show {
                transform: rotate(180deg);
                padding: 0;
                margin-right: 16px;
            }
        }

    }
    &__content-type {
        padding: 14px 16px 18px;
        .list-item-info {
            margin-bottom: 12px;
            &__user {
                font-family: Pretendard;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.17;
                letter-spacing: normal;
                text-align: center;
                color: $colorDisabled;
                padding-right: 14px;
            }
            &__time {
                font-family: Pretendard;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.17;
                letter-spacing: normal;
                text-align: center;
                color: $colorDisabled;
            }
        }
        .list-item-status {
            margin-bottom: 8px;
            span {
                padding: 4px 7px;
                border-radius: 10px;
                border: solid 1px #f24b38;
                font-family: Pretendard;
                font-size: 10px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.2;
                letter-spacing: normal;
                text-align: center;
                color: #f24b38;
                display: inline-block;
            }
        }
        .list-item-title {
            font-family: Pretendard;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: normal;
            text-align: left;
            color: #000;
            margin-bottom: 0;
        }
    }

}