.main-page{
    padding-bottom: 0 !important;
    background-color: #ffeae1;
    overflow-x: hidden;
    padding-top: 50px !important;
    .c-empty-icon{
        margin: 40px auto;
    }
    .app-logo {
        img {
            margin-left: -7px;
        }
    }
    .main-header{
        position: fixed;
        inset: 0 0 0 0;
        height: 54px;
        padding: 10px 16px;
        z-index: 99;
        background-color: rgba(253, 234, 225, 0);
        width: 100%;
        left: 0;
        top: 0;
    }
    .peach-icon{
        position: absolute;
        top: 54px;
        left: -50px;
        z-index: 0;
    }

    .top-icon{
        margin: 0 7px;
        &:last-child{
            margin-right: 0;
        }
    }

    .bg-white-transparent{
        opacity: 1;
        background-color: rgba(255,255,255, .6);
    }
    .carousel-root{
        .slider-wrapper{
            width: calc(100% - 20px);
            overflow: visible;
        }
        .carousel-status{
            top: unset;
            right: 25px;
            bottom: 0;
            margin: 0;
            text-shadow: unset;
            background: rgba(0,0,0,.4);
            font-family: Pretendard;
            font-size: 11px;
            padding: 2px 5px;
            border-radius: 10px;
            line-height: 11px;
        }
        .slide{
            padding: 4px
        }
    }

    .content{
        background: #f6f6f6;
        margin: 0 -16px;
        min-height: calc(100vh - 285px);
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: stretch;
        justify-content: space-between;
        align-items: stretch;
        padding-bottom: 76px;
    }
    section{
        padding: 16px;
        a{
            text-decoration: none;
            color: #0f0f0f;
            &:hover{
                text-decoration: none;
                color: #0f0f0f;
            }
        }
        h3{
            font-family: Pretendard;
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 0;
            img{
                vertical-align: baseline;
            }
        }
    }
    .info-area{
        position: relative;
        z-index: 1;
        height: 230px;
        padding: 16px 0;
        .clickable{
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
        }
        img{
            height: 19px;
        }
        .info-section{
            border-radius: 7px;
            margin-bottom: 10px;
        }
        .balance {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            gap: 0px 0px;
            grid-template-areas:
                "div1 div1 div1 div1 div2 div2"
                "div1 div1 div1 div1 div3 div3";
            width: 100%;

            .div1 {
                grid-area: div1;
                border-right: 1px solid #ffeae1;
                width: 100%;
                padding: 10px 16px;
                position: relative;
                h3{
                    font-family: Pretendard;
                    font-size: 16px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.44;
                    letter-spacing: -0.32px;
                    text-align: left;
                    color: #000;
                }
                button{
                    font-family: Pretendard;
                    font-size: 16px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.19;
                    letter-spacing: -0.32px;
                    text-align: center;
                    color: #ff773a;
                    padding: 5px 8px;
                    border-radius: 5px;
                    background-color: #fbe9e1;
                    border: 0;
                    position: absolute;
                    top: 7px;
                    right: 18px;
                }
                .react-loading-skeleton, .amount{
                    position: absolute;
                    bottom: 16px;
                    left: 17px;
                    font-family: Pretendard;
                    font-size: 30px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.2;
                    letter-spacing: -1.5px;
                    text-align: left;
                    color: #ff6a27;
                }
                .angle-right{
                    position: absolute;
                    right: 7px;
                    bottom: 25px;
                    img{
                        height: 10px;
                    }
                }
            }
            .div2 {
                grid-area: div2;
                border-bottom: 1px solid #ffeae1;
                text-align: center;
                padding: 15px;
            }
            .div3 {
                grid-area: div3;
                text-align: center;
                padding: 15px;
            }
        }
        .promotion{
            display: flex;
            width: 100%;
            flex-direction: row;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: space-between;
            align-items: stretch;
            .left{
                text-align: center;
                padding: 10px;
                width: 100%;
                border-right: 1px solid #ffeae1;
            }
            .right{
                text-align: center;
                padding: 10px;
                width: 100%;
            }
        }
        .name{
            padding: 10px 16px;
            font-family: Pretendard;
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.44;
            letter-spacing: -0.64px;
            text-align: left;
            color: #0f0f0f;
            strong{
                font-family: Pretendard;
                font-size: 16px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.44;
                letter-spacing: -0.64px;
                text-align: left;
                color: #0f0f0f;
            }
        }

    }
    .banner-area{
        background: #FFF;
        padding: 10px 0;
        margin-bottom: 8px;
        .slide{
            >div{
                width: 100%;
                height: 115px;
                overflow: hidden;
                border-radius: 7px;
                img{
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    .activities-area{
        background: #FFF;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 8px;
        min-height: calc(100% - 313px);
        overflow: hidden;
        .react-loading-skeleton{
            margin: 6px 0;
        }
        ul{
            padding: 0;
            margin: 0;
            li{
                list-style: none;
                display: block;
                &:last-child{
                    .list-item {
                        border-bottom: none;
                    }
                }
                a{}
                .list-item{
                    display: flex;
                    padding: 12px 0;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-content: center;
                    justify-content: space-between;
                    align-items: center;
                    line-height: normal;
                    border-bottom: 1px solid #eee;

                    .title{
                        font-family: Pretendard;
                        font-size: 14px;
                        font-weight: 500;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        width: 347px;
                    }
                    .date{
                        font-family: Pretendard;
                        font-size: 12px;
                        font-weight: normal;
                        color: #c9c7c7;
                        width: 40px;
                        text-align: right;
                    }
                }
            }
        }
    }
    .intro-area{
        background: #FFF;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 0px;
        height: 152px;
        position: relative;
        .tree{
            position: absolute;
            right: -22px;
            bottom: 0;
        }
        h3{
            font-family: SBAggroM;
            font-size: 18px;
            font-weight: normal;
            top: 44px;
            position: absolute;
        }
        p{
            font-family: Pretendard;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.21;
            letter-spacing: normal;
            text-align: left;
            color: #000;
            top: 76px;
            position: absolute;
        }
    }
    .floatCTA{
        position: fixed;
        z-index: 9999;
        bottom: 95px;
        right: 7px;
        .phoneBtn{}
        .kakaoBtn{
            margin-top: 7px;
        }
        .cacheClear{
            background: green;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;
            label{
                text-align: center;
                font-family: Pretendard;
                color: #fff;
            }
        }
        // img {
        //     object-fit: cover;
        //     width: 80px;
        //     height: 80px;
        // }
    }
    .introduce-company {
        background-color: #fff;
        padding: 20px 16px;
        h4 {
            font-family: Pretendard;
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.17;
            letter-spacing: normal;
            text-align: left;
            color: #848484;
            margin-bottom: 10px;
        }
        span {
            font-family: Pretendard;
            font-size: 11px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.18;
            letter-spacing: normal;
            text-align: left;
            color: #b8b8b8;
            display: block;
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0;
            }
            label {
                font-family: Pretendard;
                font-size: 11px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.18;
                letter-spacing: normal;
                text-align: left;
                color: #bfbfbf;
            }
            &.bold-value {
                color: #bfbfbf;
                label {
                    color: #989898;
                }
            }
        }
    }
}

@media screen and (min-height: 710px) {
    .main-page {
        .content {
            justify-content: flex-start;
            // min-height: 400px;
            // padding-bottom: 0;
        }
        .activities-area {
            padding: 0;
            background: transparent;
            min-height: 238px;
            &.empty-banner {
                min-height: 300px;
            }
            a {
                background: white;
                display: block;
                padding: 10px 16px 0;
            }
            ul {
                background: white;
                padding: 0 16px;
            }
       }
       .intro-area {
            // position: absolute;
            width: 100%;
            bottom: 0;
       }
    }
}