.referrer-page{
  padding: 0 !important;
  background-color: #ebebeb;
  .breakpoint {
    height: 11px;
  }
  header {
    background: #FFFFFF;
  }

  .summary{
    background: #FFFFFF;
    font-family: Pretendard;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #000;
    padding: 16px;
    h1{
      font-family: Pretendard;
      font-size: 28px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: normal;
      text-align: center;
      color: #000;
    }
  }

  main {
    margin-top: 10px;
    background: #FFFFFF;
    .c-empty-icon{
      margin-top: 97px;
    }
    table{
      td{
        font-family: Pretendard;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: center;
        color: #8b8b8b;
        padding: 10px;
      }
      thead{
        td{
          font-family: Pretendard;
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.19;
          letter-spacing: normal;
          text-align: center;
          color: #000;
          border-bottom: 1px solid #000;
          padding: 7px;
        }
      }
    }
    .box-title-list {
      border-bottom: 1px solid #000;
      display: flex;
      justify-content: center;
      position: absolute;
      width: 100%;
      label {
          font-family: Pretendard;
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.19;
          letter-spacing: normal;
          text-align: center;
          color: #000;
          padding: 0px 0 9px 7px;
          width: 50%;
          &::last-child {
              padding: 7px 7px 7px 14px;
          }
      }
  }
  .box-render-list {
      margin-top: 34px;
      overflow-y: scroll;
      max-height: 455px;
  }
  }
}