.categories-list{
  &__group{
    overflow-x: scroll;
    overflow-y: hidden;
    list-style: none;
    padding-left: 14px;
    overflow-x: scroll;
    padding: 16px 0px;
    margin: 0px;
    white-space: nowrap;
    &::-webkit-scrollbar {
      display: none;
    }
    &--loading{
      display: flex;
      .categories-list__group__item{
        min-width: 69px !important;
        height: 26px;
        padding: 2px 24px;
        margin-top: 2px;
      }
      .loading{
        display: inline;
        margin-right: 10px;
        .react-loading-skeleton{
          border-radius: 14px;
        }
      }
    }
    &__item{
      display: inline;
      min-width: 100px;
      border-radius: 14px;
      box-shadow: 0 2px 0 0 #bebebe;
      padding: 5px 24px;
      margin-right: 5px;
      border: solid 1px #000;
      background-color: #fff;
      &:first-child{
        margin-left: 16px;
      }
      &:last-child{
        margin-right: 16px;
      }
      label{
        font-family: Pretendard;
        font-size: 11px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        line-height: 1.18;
        color: #000000;
        padding: 0px;
      }
      &.active{
        background-color: #000;
        label{
          color: #fff;
        }
      }
    }
  }
}