@import "./src/core/assets/scss/color";

.signup-page {
  background-color: #f6f6f6;
  padding: 0 !important;
  .c-dialog-body {
    display: flex;
  }
  header {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06) !important;
    background: $white !important;
    margin-bottom: 2px;
  }
  &__main {
    margin-top: 60px;

    &__form {
      overflow-y: hidden;
      overflow-x: hidden;
      padding-bottom: 20px;
      .item-form {
        padding: 0 16px;
        background-color: $white;
        &.form-input {
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
          padding-top: 15px;
          margin-bottom: 23px;
          padding-bottom: 6px;
        }
        &.form-terms-policy {
          padding-top: 15px;
          padding-bottom: 200px;
        }
        &.item-form-btn {
          position: fixed;
          width: 100%;
          left: 0;
          bottom: 0;
          background-color: $white;
          padding: 16px;
        }
      }
      .c-form-control {
        input:disabled {
          background: #eeeeee;
        }
        input{
          &::placeholder {
            color: #8b8b8b;
          }
        }
      }
      .form-verify-code {
        margin-bottom: 20px;
        .c-input-button-group {
          input,button {
            margin-bottom: 0;
          }
          &.verify {
            button {
              &:hover {
                background-color: #ff773a;
              }
            }
          }
        }
        .group-input-phone {
          button {
            &:disabled {
              color: #ababab;
              background-color: white;
              border: solid 1px #ababab;
            }
          }
        }
        &.disable {
          .c-input-button-group {
            &.verify {
              button {
                background-color: #cecece;
                pointer-events: none;
              }
              .timer {
                display: none;
              }
            }
            input {
              pointer-events: none;
              background-color: #eeeeee;
            }
          }
          .group-input-phone {
            button {
              pointer-events: none;
            }
          }
          .group-input-phone {
            button {
              color: #ababab;
                background-color: white;
                border: solid 1px #ababab;
            }
          }
        }
        
      }
      .form-password {
        .c-form-control {
          margin-bottom: 10px;
        }
      }
      .box-line {
        background:rgba(0, 0, 0, 0.06) ;
        height: 10px;
        margin: 0 -16px;
      }
      .form-policy-and-terms {
        //margin-top: 15px;
        padding-bottom: 30px;
        margin-bottom: 60px;
        .c-form-control {
          display: block;
        }
        &__all{
          padding-bottom: 12px;
          border-bottom: solid 1px #e2e2e2;
          margin-bottom: 14px;
          .c-form-control {
            font-family: Pretendard;
            font-size: 18px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.17;
            letter-spacing: normal;
            text-align: left;
            color: $black;
          }
        }
        &__item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 14px;
          &__icon {
            padding: 0 0 0 10px;
          }
          .c-form-control {
            display: flex;
            font-family: Pretendard;
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: -0.3px;
            text-align: left;
            color: $black;
          }
        }
      }
      .form-addrees {
        .group-input-address {
          .form-control-address {
            width: calc(100% - 102px);
            .c-form-control {
              margin-bottom: 0;
              pointer-events: none;
            }
          }
          button {
            white-space: nowrap;
            width: auto;
            padding: 0 9px;
            margin-bottom: 0;
          }
        }
        .form-control-address-detail-kakao {
          input {
            margin-top: 0;
            margin-bottom: 0;
            pointer-events: none;
          }
        }
        .form-control-address-detail {
          input {
            margin-top: 0;
          }
        }
      }
    }
  }
  .dialog-terms-of-service {
    height: 100%;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    border: none;
    border-radius: 0;
    + .c-dialog-overlay {
      background: transparent;
    }
    .c-dialog-body {
      padding: 0;
      height: 100%;
      display: block;
      min-height: 100%;
      overflow-y: auto;
      .terms-of-service {
        header {
          position: fixed;
          width: 100%;
          z-index: 999;
          background: white;
        }
        &__main {
          margin-top: 54px;
          p {
            font-family: Pretendard;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: -0.28px;
            text-align: left;
            color: #000;
          }
          span {
            font-family: Pretendard;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: -0.28px;
            text-align: left;
            color: #000;
          }
        }
      }
      .policy-use {
        header {
          position: fixed;
          width: 100%;
          z-index: 999;
          background: white;
        }
        &__main {
          margin-top: 54px;
          p {
            font-family: Pretendard;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: -0.28px;
            text-align: left;
            color: #000;
          }
          span {
            font-family: Pretendard;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: -0.28px;
            text-align: left;
            color: #000;
          }
        }
      }
    }
  }
  .dialog-custom-button {
    .c-dialog-body {
      span {
        font-family: Pretendard;
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: -0.3px;
        text-align: center;
        color: #686868;
        display: inline-block;
        width: 100%;
      }
    }
  }
  .address-search{
      z-index: 9999999999;
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: #FFFFFF;
      padding: 16px;
  }
}

.terms-of-service {
  padding: 0px 16px !important;
  header {
    position: fixed;
    width: 100%;
    z-index: 999;
    background: white;
    padding: 15px 16px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
    margin: 0 -16px;
  }
  &__main {
    padding-top: 69px;
    padding-bottom: 30px;
    p {
      font-family: Pretendard;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: -0.28px;
      text-align: left;
      color: #000;
    }
    span {
      font-family: Pretendard;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: -0.28px;
      text-align: left;
      color: #000;
    }
  }
}
.policy-use {
  padding: 0px 16px !important;
  header {
    position: fixed;
    width: 100%;
    z-index: 999;
    background: white;
    padding: 15px 16px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
    margin: 0 -16px;
  }
  &__main {
    padding-top: 69px;
    padding-bottom: 30px;
    p {
      font-family: Pretendard;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: -0.28px;
      text-align: left;
      color: #000;
    }
    span {
      font-family: Pretendard;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: -0.28px;
      text-align: left;
      color: #000;
    }
  }
}

.d-grid-fixed-bottom {
  position: fixed;
  bottom: 16px;
  width: calc(100% - 32px);
  left: 16px;
  height: 54px;
  z-index: 999;
}