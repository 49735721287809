.ckey-board{
  background-color: #ebebeb;
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 99999;
  &__group{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: pxToVW(0);
    grid-row-gap: pxToVW(0);
    &__item{
      .img{
        // width: pxToVW(120);
        // height: pxToVW(61);
        img{
          width: 100%;
          height: 100%;
        }
      }
      .number-key{
        height: 61px;
        display: flex;
        justify-content: center;
        align-items: center;
        .number{
          font-family: Montserrat;
          font-size: 20px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          letter-spacing: normal;
          text-align: center;
          color: #000;
        }
      }
    }
  }
}