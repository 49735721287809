@import "color";
@import 'react-toastify/dist/ReactToastify.css';



body:has(.bottom-btn){
    .Toastify__toast-container{
        bottom: 96px;
    }
}

body:has(.bottom_navigation){
    .Toastify__toast-container{
        bottom: 96px;
    }
}

.Toastify__toast-container {
    width: calc(100vw - 32px);
    padding: 0;
    left: 16px;
    right: 16px;
    bottom: 16px;
    margin: 0;
    .Toastify__toast {
        min-height: unset;
        padding: 10px 16px;
        border-radius: 6px;
        border: solid 1px rgba(255, 119, 58, 0.6);
        background-color: #fef6f2 !important;
        margin-bottom: 16px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

    }
    .Toastify__toast-body {
        margin: auto 0;
        flex: 1 1 auto;
        padding: 0;
        display: flex;
        align-items: center;
        font-family: Pretendard;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: -0.28px;
        text-align: center;
        color: #ff773a;
        div {
            white-space: break-spaces;
        }
    }
}


.toast-container {
    text-align: center;
    position: fixed;
    bottom: -85px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 990;
    pointer-events: none;
    transition: 0.1s;
    &.show {
        bottom: 85px;
        transition: 0.4s;
        background-color: $white;
    }
    span {
        font-family: Pretendard;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: -0.28px;
        text-align: center;
        color: #ff773a;
        white-space: pre;
        min-width: 150px;
        display: block;
    }
    .toast {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        box-shadow: none;
        border: solid 1px rgba(255, 119, 58, 0.6);
        background-color: rgba(255, 170, 132, 0.1);
        padding: 10px 15px;
        border-radius: 6px;
        &.toast-light {
            box-shadow: none;
            width: auto;
            display: block;
        }

    }
}



.loading-page{
    width: 100%;
    height: 100%;
    z-index: 1000005;
    .img-loading{
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1000005;
    }
  }
  
  .loading-page:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000005;
    background: #bdb8b847;
  }
  