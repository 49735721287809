.payment-success{
	background-color: #fff;
	padding: 0 !important;

  main{
    margin-top: 10px;
    background-color: #fff;
    // height: calc(100vh - 64px);
    .content{
      &__reason{
        padding: 63px 0px 35px 0px;
        p.top{
          font-family: Pretendard;
          font-size: 26px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.31;
          letter-spacing: normal;
          text-align: center;
          margin-bottom: 0px;
          color: #000;
          
        }
        p.bottom{
          font-family: Pretendard;
          font-size: 26px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.31;
          letter-spacing: normal;
          text-align: center;
          margin-bottom: 0px;
          color: #ff773a;
        }
        &--money{
          p.top{
            font-family: Pretendard;
            font-size: 19px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.26;
            letter-spacing: -0.57px;
            text-align: center;
            color: #000;
          }
        }
      }
      &__method{
        border-bottom: 1px solid #e2e2e2;
        padding-bottom: 12px;
        p{
          &:first-child{
            font-family: Pretendard;
            font-size: 17px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.41;
            letter-spacing: normal;
            text-align: center;
            color: #ff773a;
            margin-bottom: 0px;
          }
          &:last-child{
            font-family: Pretendard;
            font-size: 17px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.41;
            letter-spacing: normal;
            text-align: center;
            color: #000;
          }
        }
        &--money{
          border-bottom: none;
         
        }
      }
      .notes{
        border-radius: 4px;
        background-color: #ecf3ff;
        padding: 9px 0px;
        text-align: center;
        margin: 0px 16px 30px 16px;
        p{
          margin: 0px;
          padding: 0px;
          font-family: Pretendard;
          font-size: 13px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          color: #000000;
          span{
            color: #3a82ff;
          }

        }

      }
      .info{
        padding: 24px 0px;
        border-top: 1px solid #e2e2e2;
        border-bottom: 1px solid #e2e2e2;
        margin: 0px 16px;
        p{
          display: flex;
          justify-content: space-between;
          label{
            opacity: 0.5;
            font-family: Pretendard;
            font-size: 17px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.41;
            letter-spacing: normal;
            text-align: center;
            color: #000;
          }
          span{
            font-family: Pretendard;
            font-size: 18px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.04;
            letter-spacing: normal;
            text-align: center;
            &.text-highlight{
              font-size: 23px;
              color: #ff773a;
            }
          }
          span.payment-amount{
            font-family: Pretendard;
            font-size: 23px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: center;
            color: #ff773a;
            padding: 0px;
          }
          // &:last-child{
          //   span{
          //     font-family: Pretendard;
          //     font-size: 18px;
          //     font-weight: 600;
          //     font-stretch: normal;
          //     font-style: normal;
          //     line-height: 1.33;
          //     letter-spacing: normal;
          //     text-align: center;
          //     color: #000;
          //   }
          // }
        }
        .payment-method{
          margin-bottom: 0px;
          span{
            font-family: Pretendard;
            font-size: 15px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.47;
            letter-spacing: normal;
            text-align: right;
            color: #000;
          }
        }
      }
      &__button{
        padding: 15px 16px 20px 16px;
        .item{
          &__left{
            .d-grid{
              button{
                background-color: #ebebeb;

                font-family: Pretendard;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.19;
                letter-spacing: normal;
                text-align: center;
                color: #000;
              }
            }
          }
        }
      }
    }
  }
}