#bottom_navigation{
  height: 76px;
  margin: 12px 0 0;
  padding: 18.9px 24px 19px;
  box-shadow: 0 -2px 2px 0 rgba(0, 0, 0, 0.06);
  background-color: #fff;
  width: 100vw;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: flex-end;
  z-index: 9999;
  li{
    list-style: none;
    font-family: Pretendard;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: center;
    color: #000;
    a{
      img{}
    }
  }
}