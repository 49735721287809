@import "color";

.c-empty-icon {
    .box-content {
        margin: auto;
        text-align: center;
        &__icon {
            margin-bottom: 15px;
        }
        &__text {
            font-family: Pretendard;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.25;
            letter-spacing: normal;
            text-align: center;
            color: #a2a2a2;
            margin-bottom: 4px;
        }
        &__description {
            opacity: 0.7;
            font-family: Pretendard;
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            text-align: center;
            color:$colorDisabled;
            margin-bottom: 0;
        }
    }
}