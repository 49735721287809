.payment-failed{
	background-color: #fff;
  main{
    margin-top: 10px;
    background-color: #fff;
    // height: calc(100vh - 64px);
    .content{
      &__reason{
        padding: 68px 0px 58px 0px;
        p{
          font-family: Pretendard;
          font-size: 26px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.31;
          letter-spacing: normal;
          text-align: center;
          margin-bottom: 0px;
          &:first-child{
            color: #ff773a;
          }
          &:last-child{
            color: #000;
          }
        }
      }
      &__method{
        border-bottom: 1px solid #e2e2e2;
        padding-bottom: 12px;
        p{
          &:first-child{
            font-family: Pretendard;
            font-size: 17px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.41;
            letter-spacing: normal;
            text-align: center;
            color: #ff773a;
            margin-bottom: 0px;
          }
          &:last-child{
            font-family: Pretendard;
            font-size: 17px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.41;
            letter-spacing: normal;
            text-align: center;
            color: #000;
          }
        }
      }
      &__button{
        padding: 15px 0px 20px 0px;
        .item{
          &__left{
            .d-grid{
              button{
                background-color: #ebebeb;

                font-family: Pretendard;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.19;
                letter-spacing: normal;
                text-align: center;
                color: #000;
              }
            }
          }
        }
      }
    }
  }
}