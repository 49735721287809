.notification-page{
  padding: 54px 0 0 0 !important;
  background-color: #ebebeb;


  header {
    background: #FFFFFF;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
  }


  main, .list {
    margin-top: 10px;
    background: #FFFFFF;
    padding: 0px;
    padding-top: 14px;
    overflow-y: scroll;
    .c-empty-icon{
      margin-top: 97px;
    }
    .c-list-item{
      &:first-child{
        padding-top: 0;
        border-top: none;
      }
      &.read{
        .item-title {
          color: #c9c7c7
        }
      }
      .item-title {
        // white-space: pre;
        div {
          word-break: break-word;
        }
      }
    }
  }
}