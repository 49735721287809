.static-page {
  padding: 64px 0 0 0 !important;
  background-color: #ebebeb;
  height: 100vh;
;

  header {
    background: #FFFFFF;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 99999;
  }

  main {
    padding: 16px;
    background: #FFFFFF;
  }
  h1{
    margin-bottom: 20px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: left;
    color: #000;
  }
  h3{
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    color: #000;
  }
  dl{
    margin-bottom: 20px;
    dt {
      font-family: Pretendard;
      font-size: 14px;
      font-weight: normal !important;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.86;
      letter-spacing: normal;
      text-align: left;
      color: #8b8b8b;
      position: relative;
      padding-left: 5px;
      &:before{
        content: '';
        display: block;
        width: 2px;
        height: 2px;
        background: #8b8b8b;
        position: absolute;
        left: 0;
        top: 10px;
      }
    }
    dd{
      padding-left: 7px;
      font-family: Pretendard;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.86;
      letter-spacing: normal;
      text-align: left;
      color: #8b8b8b;
    }

  }
}
.member-ranking-page{

  .content{
    margin-top: 12px;
    font-family: Pretendard;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.73;
    letter-spacing: normal;
    text-align: left;
    color: #8b8b8b;
    strong{
      font-weight: 500;
      color: #000;
    }
  }
}
.company-introduction-page{
  main{
    text-align: center;
  }
  .app-logo{
    margin: auto;
    margin-bottom: 16px;
  }
  .com-intro-img{
    width: 100%;
    min-height: 200px;
    img{
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .section-1, .section-3{
    margin: 30px auto;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.28px;
    text-align: center;
    color: #707070;
  }
  .quote{
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: center;
    color: #333;
    margin-bottom: 100px;
    .quote-icon{
      margin: 20px;
      img{
        width: 20px;
      }
    }
  }

  .section-2{
    ul{
      padding: 0;
      width: calc(100vw - 66px);
      margin: auto;
      li{
        list-style: none;
        &:last-child .timeline-item{
          padding-bottom: 10px;
          &:after{
            display: none;
          }
        }
        .timeline-item{
          display: flex;
          width: 100%;
          flex-direction: row;
          flex-wrap: nowrap;
          position: relative;
          padding-bottom: 60px;
          &:after{
            content: '';
            display: block;
            position: absolute;
            width: 1px;
            height: 40px;
            background: #ebebeb;
            left: 50%;
            bottom: 16px;
          }

          .left{
            flex: 3;
            text-align: right;
            .time{
              padding: 5px 10px;
              border-radius: 13px;
              background-color: #ff773a;
              font-family: Pretendard;
              font-size: 14px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.71;
              letter-spacing: -0.28px;
              text-align: center;
              color: #fff;
              display: unset;
              margin-right: 10px;
            }
          }
          .right{
            flex: 7;
            text-align: left;
            font-family: Pretendard;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: -0.28px;
            text-align: left;
            color: #8c8c8c;
            span{
              color: #ff773a;
            }
          }
        }
      }
    }
  }
}