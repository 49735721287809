@import "color";

.c-dialog{
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    width: calc(100vw - 36px);
    transform: translate(-50%, -50%);
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: stretch;
    background: #FFF;
    border-radius: 5px;
    z-index: 99999;
    &.show{
        display: flex;
        +.c-dialog-overlay{
            display: flex;

        }
    }
    h3{
        text-align: center;
        margin: 0;
        font-family: NotoSansCJKKr;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #7833ba;
    }
    &-body{
        padding: 0 30px 0px 30px;
        min-height: 110px;
        align-items: center;
    }

    .btn{
        border: 0;
        border-radius: 0;

    }

    +.c-dialog-overlay{
        display: none;
        z-index: 99998;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: $black;
        opacity: .5;
    }
    &-footer {
        height: 48px;

        button {
            width: 100%;
            height: 100%;
            font-family: Pretendard;
            font-size: 15px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: -0.3px;
            text-align: center;
            color: $white;

            &.cancel {
                background-color: #f6f6f6;
                color: #707070;
                border-radius: 0 0 5px 5px;
            }
            &.submit {
                background-color: $orange;
                border-radius: 0 0 5px 5px;
            }
        }
        &.full-btn {
            button {
                width: 50%;
                height: 100%;
                &.cancel {
                    background-color: #f6f6f6;
                    color: #707070;
                    border-radius: 0 0 0 5px;
                }
                &.submit {
                    background-color: $orange;
                    border-radius: 0 0 5px 0;
                }
            }

        }
    }
}