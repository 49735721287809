@import "color";

$c-font-family: Pretendard;
$c-font-size: 15px;
$c-font-weight-normal: normal;
$c-font-stretch-normal: normal;
$c-font-style-normal: normal;
$c-letter-spacing-normal: normal;
$c-color-gray: #8b8b8b;
$c-color-primary: $bgPrimary;
$c-color-red: red;
$c-max-height-dropdown-menu: 250px;


.c-form-control {
  margin: 0;

  .right-desc{
    font-family: Pretendard;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: -0.24px;
    color: #8b8b8b;
    .required{
      color: red;
    }
  }

  input[type=checkbox] {
    display: none;
    &+img {
      margin-right: 5px;
    }
  }
  input[type=radio]{
    display: none;
    &+span{
      display: inline-block;
      width: 18px;
      height: 18px;
      margin-right: 5px;
      border: solid 1px #8b8b8b;
      background-color: #fff;
      vertical-align: middle;
      position: relative;
      border-radius: 50%;
    }
    &:checked+span{
      border: solid 4px #ff773a;
    }
  }

  input:disabled {
    background: #e2e2e2;
  }

  input::placeholder,
  textarea::placeholder {
    opacity: 0.7;
    font-family: $c-font-family;
    font-size: $c-font-size;
    font-weight: $c-font-weight-normal;
    font-stretch: $c-font-stretch-normal;
    font-style: $c-font-style-normal;
    letter-spacing: $c-letter-spacing-normal;
    color: $c-color-gray;
  }

  .c-label {
    height: 16px;
    margin: 0;
    font-family: $c-font-family;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    color: #000;

    .required {
      color: $c-color-red;
    }
  }

  .c-desc {
    height: 14px;
    margin-top: 4px;
    margin-bottom: 0;
    font-family: $c-font-family;
    font-size: 12px;
    font-weight: $c-font-weight-normal;
    font-stretch: $c-font-stretch-normal;
    font-style: $c-font-style-normal;
    line-height: 1.17;
    letter-spacing: $c-letter-spacing-normal;
    text-align: left;
    color: $c-color-gray;
  }

  input[type='text'],
  input[type='number'],
  input[type='phone'],
  input[type='password'] {
    height: 40px;
    font-family: $c-font-family;
    font-size: 14px;
    margin: 10px 0;
    padding: 10px;
    border-radius: 6px;
    border: solid 1px #e2e2e2;
    width: 100%;
  }
  textarea{
    font-family: $c-font-family;
    font-size: 14px;
    margin: 10px 0;
    padding: 10px;
    border-radius: 6px;
    border: solid 1px #e2e2e2;
    width: 100%;
  }

  .c-input-wrapper {
    position: relative;

    &.show-hide-password {
      input, .markup {
        padding-right: 40px;
      }
      input{
        color: transparent;
      }
    }

    //input[type='password'] {
    //  color: transparent;
    //}

    .markup {
      position: absolute;
      top: 10px;
      left: 0;
      padding: 10px;
      font-size: 18px;
    }

    .password-icon {
      position: absolute;
      top: 20px;
      right: 12px;
    }
  }

  .c-input-button-group {
    display: flex;
    .box-c-phone-number {
      width: calc(100% - 75px);
    }
    > input {
      width: calc(100% - 75px);
    }

    .input {
      width: calc(100% - 75px);
      position: relative;

      input {
        padding-right: 55px;
      }

      span.timer {
        position: absolute;
        top: 20px;
        right: 12px;
        font-family: $c-font-family;
        font-size: 14px;
        font-weight: 500;
        color: $c-color-red;
      }
    }

    > button {
      height: 40px;
      font-family: $c-font-family;
      font-size: 15px;
      font-weight: $c-font-weight-normal;
      font-stretch: $c-font-stretch-normal;
      font-style: $c-font-style-normal;
      margin: 10px 0;
      margin-left: 5px;
      border-radius: 6px;
      width: 70px;
      text-align: center;
      padding: 0 5px;
    }
  }
  .c-dropdown-wrapper{
    .c-dropdown-input {
      position: relative;
      input {
      }

      .icon {
        position: absolute;
        top: 20px;
        right: 20px;
        &.up{
          transform: rotate(180deg);
        }
      }
    }
    .c-dropdown-menu{
      visibility: hidden;
      position: absolute;
      border-radius: 6px;
      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.05);
      border: solid 0.5px #e2e2e2;
      background-color: #fbfbfb;
      z-index: 9999;
      max-height: $c-max-height-dropdown-menu;
      overflow: hidden;
      &__list{
        // display: flex;
        // flex-direction: column;
        // flex-wrap: nowrap;
        // align-content: center;
        // justify-content: center;
        // align-items: stretch;
        height: 100%;
        overflow-y: scroll;
        max-height: $c-max-height-dropdown-menu;
        ul {
          padding: 0;
          margin: 0;
          li {
            list-style: none;
            display: block;
            padding: 16px;
            border-bottom: solid 1px #eee;
            width: 100%;
            text-align: center;
            &:last-child {
              border: 0;
            }
            &.disabled{
              background-color: #eee;
              color: #726969;
            }
          }
        }
      }
    }
  }
  &--radio{
    display: flex;
  }
}

.c-form-error {
  margin: 0 0 10px 0;
  font-family: $c-font-family;
  font-size: 12px;
  font-weight: $c-font-weight-normal;
  font-stretch: $c-font-stretch-normal;
  font-style: $c-font-style-normal;
  line-height: 1.17;
  letter-spacing: $c-letter-spacing-normal;
  text-align: left;
  color: $c-color-red;
  display: flex;

  span {
    display: block;

    &:nth-child(1) {
      width: 10px;
    }
  }
}

.btn-primary, .cbtn:active {
  background-color: $c-color-primary;
  border: 0;
  color: #FFF;
}

.cbtn:disabled {
  background: $bgDisabled;
  border: 0;
}

.cbtn {
  &.text-light {}

  &.text-dark {
    color: $c-color-gray !important;
  }
}

.btn-outline-primary {
  background: #FFF;
  border: 1px solid $c-color-primary;
  color: $c-color-primary;
}

.btn-check:checked + .btn,
:not(.btn-check) + .btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  color: #FFF;
  background-color: $c-color-primary;
  border-color: $c-color-primary;
}

.d-grid {
  .cbtn {
    font-family: $c-font-family;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: center;
    padding: 18px;
    border-radius: 6px;
    height: 54px;
  }
}
.bottom-btn{
  position: fixed;
  z-index: 9999;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  background: #FFF;
  button{
    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.15);

  }
}

.c-file-picker{
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  .picker{
    width: 72px;
    height: 72px;
    margin: 10px 0;
    margin-right: 12px;
  }
  .image-item{
    position: relative;

    .thumb{
      width: 72px;
      height: 72px;
      border-radius: 6px;
      background-color: #efefef;
      margin: 10px 0;
      margin-right: 12px;
      overflow: hidden;
      img{
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    .remove-icon{
      position: absolute;
      top: 3px;
      right: 3px;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      overflow: hidden;
      //background: #FFF;
      img{
        width: 22px;
        height: 22px;
        opacity: 1;
      }
    }
  }
  .description{
    display: block;
    width: 100%;
    font-family: Pretendard;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: -0.24px;
    text-align: left;
    color: #8b8b8b;
  }
}
.count-chacter {
  display: flex;
  justify-content: end;
  margin-top: -5px;
  label.length {
    font-family: Pretendard;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: right;
    color: #858585;
  }
}