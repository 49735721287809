.bottom-swiper {
  &.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba($color: $colorBlack, $alpha: 0.6);
    z-index: 10000;
  }

  &__main {
    width: 100%;
    position: fixed;
    bottom: 0;
    height: auto;
    background-color: $white;
    border-top-left-radius: pxToVW(28);
    border-top-right-radius: pxToVW(28);

    &__swipe-down {
      display: flex;
      justify-content: center;
      height: 36px;
      .icon{
        width: 27px;
        height: 4px;
        border-radius: 30px;
        background-color: $colorGrey;
        margin-top: 10px;
      }
    }

    &__title {
      padding-top: 0;
      // height: pxToVW(27);
      
      // display: flex;
      // justify-content: center;
      // align-items: left;
      h2{
        max-width: calc(100% - 25px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        word-break: break-word;
        font-family: Pretendard;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.11;
        letter-spacing: normal;
        text-align: left;
        color: #000;
      }
    }

    &__suggest {
      font-size: pxToVW(14);
      font-family: Pretendard;
      color: $colorBlack;
      height: pxToVW(20);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: pxToVW(5);
      margin-bottom: pxToVW(0);
      color: $colorGrey;
      font-weight: $normal;
      word-break: break-word;
    }
    &::before {
      position: absolute;
      width: pxToVW(27);
      height: pxToVW(4);
      content: '';
      top: pxToVW(10);
      border-radius: pxToVW(5);
      background-color: $colorGrey;
      left: 50%;
      transform: translateX(-50%);
    }
    &__header {
      text-align: center;
      font-size: pxToVW(18);
      font-weight: $bold;
      color: $colorBlack;
      z-index: 1;
      border-bottom: 1px solid $borderDisabled;
      padding-bottom: pxToVW(20);
    }

    &__body {
      // padding: pxToVW(20) pxToVW(16) pxToVW(20);
      color: $colorBlack;
      height: pxToVW(350);
      overflow: auto;
    }
    &__footer {
      margin: pxToVW(16);
      color: $colorBlack;
      height: pxToVW(130);
    }

    &.back {
      animation: touchDownDefault 0.6s ease-in-out both;

      @keyframes touchDownDefault {
        0% {
          top: calc(100vh - #{pxToVW(420)});
        }

        100% {
          top: pxToVW(1000);
        }
      }
    }
    &.touchend {
      animation: touchUpDefault 0.6s ease-in-out both;

      @keyframes touchUpDefault {
        0% {
          top: pxToVW(1000);
        }

        100% {
          top: calc(100vh - #{pxToVW(420)});
        }
      }
    }
  }
  &--bank-select{
    .bottom-swiper__main__header{
      .bottom-swiper__main__title{
        h2{
          text-align: center;
        }
      }
    }
    .bottom-swiper__main__body {
      padding: pxToVW(15) pxToVW(20);
      color: $colorBlack;
      height: pxToVW(390); //390
      overflow: auto;
    }
    .back {
      animation: touchDownBankSlect 0.6s ease-in-out both;

      @keyframes touchDownBankSlect {
        0% {
          top: calc(100vh - #{pxToVW(487)}); //487
        }

        100% {
          top: pxToVW(1000);
        }
      }
    }
    .touchend {
      animation: touchUpBankSelect 0.6s ease-in-out both;

      @keyframes touchUpBankSelect {
        0% {
          top: pxToVW(1000);
        }

        100% {
          top: calc(100vh - #{pxToVW(487)}); //487
        }
      }
    }
  }
  &--order-detail{
    .bottom-swiper__main__body{
      position: relative;
      // padding: pxToVW(10) 0 0 0;
      margin-top: 10px;
      // height: pxToVW(380);
      &--height-err{
        height: pxToVW(415);
      }
      background-color: #fff;
      .cspin-number{
        background-color: #fff;
      }
      .order-info{
        background-color: #fff;
      }
      .payment-amount{
        background-color: #fff;
      }
    }
    .bottom-swiper__main__header{
      padding: 0 0 pxToVW(10) pxToVW(14);
      .bottom-swiper__main__title{
        font-family: Pretendard;
        font-size: pxToVW(18);
        font-weight: $bold;
        line-height: 1;
        color: $colorBlack;
        justify-content: left;
        word-break: break-word;
        text-align: left;
      }
    }

    .back {
      animation: touchDownOrder 0.6s ease-in-out both;

      @keyframes touchDownOrder {
        0% {
          top: calc(100vh - #{pxToVW(430)});
        }

        100% {
          top: pxToVW(1000);
        }
      }
    }
    .touchend {
      animation: touchUpOrder 0.6s ease-in-out both;

      @keyframes touchUpOrder {
        0% {
          top: pxToVW(1000);
        }

        100% {
          top: calc(100vh - #{pxToVW(430)});
        }
      }
    }
    .touchend-error {
      animation: touchUpOrderErr 0.0s ease-in-out both;
      @keyframes touchUpOrderErr {
        0% {
          top: pxToVW(1000);
        }

        100% {
          top: calc(100vh - #{pxToVW(490)});
        }
      }
    }
    .back-error {
      animation: touchDownOrder 0.0s ease-in-out both;

      @keyframes touchDownOrder {
        0% {
          top: calc(100vh - #{pxToVW(490)});
        }

        100% {
          top: pxToVW(1000);
        }
      }
    }
  }
  &--keyboard{
    .bottom-swiper__main__body{
      position: relative;
      // padding: pxToVW(10) 0 0 0;
      margin-top: 10px;
      // height: pxToVW(380);
      background-color: #ebebeb;
      .cspin-number{
        background-color: #fff;
      }
    }
    .bottom-swiper__main__header{
      padding: 0 0 pxToVW(10) pxToVW(14);
      .bottom-swiper__main__title{
        font-family: Pretendard;
        font-size: pxToVW(18);
        font-weight: $bold;
        line-height: 1;
        color: $colorBlack;
        justify-content: left;
        word-break: break-word;
        text-align: left;
      }
    }

    .back {
      animation: touchDownKeyboard 0.6s ease-in-out both;

      @keyframes touchDownKeyboard {
        0% {
          top: calc(100vh - #{pxToVW(420)});
        }

        100% {
          top: pxToVW(1000);
        }
      }
    }
    .touchend {
      animation: touchUpKeyboard 0.6s ease-in-out both;

      @keyframes touchUpKeyboard {
        0% {
          top: pxToVW(1000);
        }

        100% {
          top: calc(100vh - #{pxToVW(420)});
        }
      }
    }
  }
  &--keyboard-order{
    .bottom-swiper__main{
      background-color: #ebebeb;
    }
    .bottom-swiper__main__body{
      position: relative;
      margin-top: 0px;
      .cspin-number{
        background-color: #fff;
        padding-top: 20px;
      }
      .order-info{
        background-color: #fff;
      }
      .payment-amount{
        background-color: #fff;
      }
    }
    .bottom-swiper__main__header{
      background-color: #fff;
      padding: 0 0 pxToVW(10) pxToVW(14);
      border-top-left-radius: 7.77778vw;
      border-top-right-radius: 7.77778vw;
      .bottom-swiper__main__title{
        font-family: Pretendard;
        font-size: pxToVW(18);
        font-weight: $bold;
        line-height: 1;
        color: $colorBlack;
        justify-content: left;
        word-break: break-word;
        text-align: left;
      }
    }

    .back {
      animation: touchDownOrder 0.6s ease-in-out both;

      @keyframes touchDownOrder {
        0% {
          top: calc(100vh - #{pxToVW(430)});
        }

        100% {
          top: pxToVW(1000);
        }
      }
    }
    .touchend {
      animation: touchUpOrder 0.6s ease-in-out both;

      @keyframes touchUpOrder {
        0% {
          top: pxToVW(1000);
        }

        100% {
          top: calc(100vh - #{pxToVW(430)});
        }
      }
    }
  }
}
