.bank-select{
  &__group{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: pxToVW(10);
    grid-row-gap: pxToVW(0);
    &__item{
      height: pxToVW(72);
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      .img{
        width: pxToVW(34);
        height: pxToVW(26);
        margin-bottom: pxToVW(5);
        img{
          width: 100%;
          height: 100%;
        }
      }
      .name-loading{
        margin-top: 5px;
      }
      .bank-name{
        font-family: Pretendard;
        font-size: pxToVW(14);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.36;
        letter-spacing: normal;
        text-align: center;
        color: #000;
      }
    }
  }
}