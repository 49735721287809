@import "./src/core/assets/scss/color";

.delete-member-page {
    background: #f6f6f6;
    padding: 0 !important;
    .box-line {
        height: 10px;
        background: #f6f6f6;
        display: none;
    }
    header {
        margin: 0 0;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
        background: $white;
    }

    &__main {
        margin-top: 10px;
        //background: $white;
        // min-height: 90vh;
        position: relative;
        overflow-y: scroll;
        &.empty-main {
            background: #f6f6f6;
        }

        &__content {

            .box-content {
                &__attention {
                    background: $white;
                    padding: 14px 15px 20px;

                    h4 {
                        font-family: Pretendard;
                        font-size: 16px;
                        font-weight: 600;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.19;
                        letter-spacing: -0.32px;
                        text-align: left;
                        color: #000;
                        margin-bottom: 8px;
                    }

                    p {
                        font-family: Pretendard;
                        font-size: 14px;
                        font-weight: 500;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.14;
                        letter-spacing: -0.28px;
                        text-align: left;
                        color: #f10000;
                        margin-bottom: 0;
                    }

                    span {
                        font-family: Pretendard;
                        font-size: 14px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.14;
                        letter-spacing: -0.28px;
                        text-align: left;
                        color: #000;
                    }

                    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
                }

                &__point {
                    background: $white;
                    padding: 20px 15px;
                    margin: 10px 0;
                    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    &__text {
                        font-family: Pretendard;
                        font-size: 16px;
                        font-weight: 500;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.19;
                        letter-spacing: -0.32px;
                        text-align: left;
                        color: #000;
                    }

                    &__value {
                        font-family: Pretendard;
                        font-size: 16px;
                        font-weight: 500;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.19;
                        letter-spacing: -0.32px;
                        text-align: right;
                        color: #000;
                    }
                }

                &__agree-terms {
                    background: $white;
                    //position: absolute;
                    bottom: 0;
                    height: auto;
                    left: 0;
                    width: 100%;
                    padding: 18px 16px;
                    .content-checkbox {
                        display: flex;
                        p {
                            font-family: Pretendard;
                            font-size: 15px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.27;
                            letter-spacing: -0.3px;
                            text-align: left;
                            color: #000;
                            margin-top: -2px;
                        }
                    }
                }
                &__submit {
                    width: 100%;
                    background: $white;
                    left: 0;
                    bottom: 0;
                    padding: 16px;
                    height: auto;
                    .cbtn:disabled {
                        color: $white;
                    }
                }
            }

        }
    }
    .modal-confirm-delete-member {
        .c-dialog-body {
            display: flex;
            span {
                font-family: Pretendard;
                font-size: 15px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.2;
                letter-spacing: -0.3px;
                text-align: center;
                color: #686868;
                display: inline-block;
                width: 100%;
            }
        }
    }
}