@import "color";

header {
  padding: 15px 16px 15px;
  &.fixed-header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999;
  }
  .header-wrapper {
    .header-content {
      display: flex;
      justify-content: space-between;
      width: 100%;
      h2 {
        font-family: Pretendard;
        font-size: 20px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: center;
        color: #000;
        margin: 0;
      }
      .left {
        display: flex;
        align-items: center;
        img {
          padding: 0 8px 0 0;
          display: block;
        }

      }
      .center {
          width: 100%;
      }
      .btn-action-right {
        img {
          padding-left: 17px;
        }
      }
    }
  }
  &.icon-header {
    .header-wrapper {
      .header-content {
        h2 {
          // max-width: calc(100% - 20px);
          // white-space: nowrap;
          // overflow: hidden;
          // text-overflow: ellipsis;
        }
        .left {
          // max-width: calc(100% - 25px);
          // min-width: calc(100% - 25px);
          img {
          }
          a {
            pointer-events: none;
          }
        }
        .center {
          width: 100%;
        }
        .btn-action-right {
          img {

          }
        }
      }
    }
    &.limit-text {
      .header-wrapper {
        .header-content {
          .left {
            max-width: calc(100% - 60px);
            min-width: calc(100% - 60px);
            h2 {
              max-width: calc(100% - 20px);
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
}