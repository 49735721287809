.accumulate-page{
  padding: 0 !important;
  background-color: #ebebeb;

  header {
    background: #FFFFFF;
  }

  .summary{
    background: #FFFFFF;
    font-family: Pretendard;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #000;
    position: relative;

    .benefit-information {
      position:absolute;
      top: 0;
      right: 16px;
      display: flex;
      label {
        font-family: Pretendard;
        font-size: 10px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.24;
        letter-spacing: normal;
        text-align: left;
        color: #444970;
        // padding: 5px;
        border-radius: 50%;
        border: 1px solid #444970;
        width: 11px;
        height: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 2px;
      }
      span {
        font-family: Pretendard;
        font-size: 10.5px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.24;
        letter-spacing: normal;
        text-align: left;
        color: #444970;
        text-decoration: underline;
      }
    }
    h1{
      font-family: Pretendard;
      font-size: 28px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: normal;
      text-align: center;
      color: #ff773a;
      margin-bottom: 0;
    }
    p{
      font-family: Pretendard;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: center;
      color: #8b8b8b;
    }
    button{
      padding: 7px 19px;
      margin-bottom: 16px;
      font-family: Pretendard;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
    }
    .note{
      position: absolute;
      top: 0px;
      right: 10px;

      display: flex;
      justify-content: center;
      align-items: center;
      .icon{
        height: 12px;
        width: 12px;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 2px;

        span{
          font-family: Pretendard;
          font-size: 9.5px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: left;
          color: #444970;
        }
      }
      .text{
        font-family: Pretendard;
        font-size: 10.5px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.24;
        letter-spacing: normal;
        text-align: left;
        color: #444970;
        border-bottom: 1px solid #4449708c;
      }
    }
  }

  main {
    margin-top: 10px;
    background: #FFFFFF;
    .c-empty-icon{
      margin-top: 97px;
    }
    .filter{
      padding: 16px;
      .date-ranger-filter{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: center;
        input{
          padding: 10px;
          border-radius: 6px;
          border: solid 1px #e2e2e2;
          font-family: Pretendard;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          letter-spacing: normal;
          text-align: center;
          color: #8b8b8b;
          width: 100%;
          opacity: 1;
          &:disabled {
            background-color: #eee !important;
          }
        }
        .division{
          width: 15px;
          text-align: center;
          position: relative;
          margin: 0 2PX;
          &:after{
            content: '';
            display: block;
            background: #c4c4c4;
            height: 1px;
            width: 9px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
          }
        }

      }
      .time-option{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
        label {
          input {
            display: none;

            & + span {
              padding: 5px 20px;
              border-radius: 6px;
              border: solid 1px #444970;
              font-family: Pretendard;
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.14;
              letter-spacing: normal;
              text-align: center;
              color: #444970;
              display: inline-block;
            }
            &:checked+span{
              color: #FFF;
              background-color: #444970;
            }
          }
        }
      }

    }
    .display{
      padding: 0px 16px 16px;
      padding-bottom: 3px;
      border-bottom: solid 1px #e2e2e2;
      font-family: Pretendard;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      color: #8b8b8b;
      select{
        border: 0;
        background: #FFF;
      }
      .dropdown-status {
        .c-dropdown-input {
          input {
            border: none;
            margin: 0;
            padding: 0;
            height: 20px;
          }
          .icon {
            position: absolute;
            top: 50%;
            left: 35px;
            transform: translateY(-50%);
          }
        }
        .c-dropdown-wrapper {
          max-width: 95px;
          .c-dropdown-menu {
            top: 352px !important;
          }
        }
        .c-form-control {
          .c-dropdown-wrapper {
            .c-dropdown-menu__list {
              ul {
                li {
                  font-family: Pretendard;
                  font-size: 14px;
                  font-weight: 500;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.14;
                  letter-spacing: -0.28px;
                  text-align: center;
                  color: #686868;
                  padding: 10px 16px;
                }
              }
            }
          }
        }
      }
      .text-total {
        display: flex;
        justify-content: end;
        align-items: center;
      }
    }
    .list{
      overflow-y: scroll;
    }
    .acm-item{
      border-bottom: solid 1px #e2e2e2;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: flex-start;
      justify-content: space-between;
      align-items: flex-start;
      padding: 16px;
      flex: 10;
      .date{
        width: 50px;
        font-family: Pretendard;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: left;
        color: #000;
      }
      .icon{
        width: 60px;
        margin-right: 20px;
        text-align: center;
      }
      .content{
        flex: 7;
        text-align: left;
        h3{
          ont-family: Pretendard;
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.19;
          letter-spacing: normal;
          text-align: left;
          color: #000;
          margin-bottom: 0px;
        }
        p{
          font-family: Pretendard;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          letter-spacing: normal;
          text-align: left;
          color: #8b8b8b;
          margin-bottom: 0;
          
          &.limit-text-name {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
            max-width: calc(100% - 45px);
          }
          &.has-text-price {
            position: absolute;
            right: -5px;
            bottom: 0;
          }
        }
      }

      .info{
        width: 50px;
        text-align: right;
        font-family: Pretendard;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: right;
        color: #8b8b8b;
        .amount{
          font-family: Pretendard;
          font-size: 15px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          letter-spacing: normal;
          text-align: right;
          color: #467eff;
          &.minus{
            color:#000000;
          }
        }
      }

    }
  }
}

.withdraw-form{
  main{
    padding: 16px;
    overflow-y: scroll;
    //border: 16px solid #FFF;
    .group-bank-name {
      position: relative;
      .icon-arrow {
        position: absolute;
        bottom: 25px;
        right: 10px;
        pointer-events: none;
      }
    }
    .content {
      
      ul {
        list-style-type: none;
        padding-left: 0;
        li {
          position: relative;
          padding-left: 9px;
          font-family: Pretendard;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          letter-spacing: normal;
          text-align: left;
          color: #8b8b8b;
          &::before {
            content: "-";
            position: absolute;
            left: 0;
          }
        }
      }
      p {
        font-family: Pretendard;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: left;
        color: #8b8b8b;
      }
    }
  }
  .request_withdraw_dialog{
    font-family: Pretendard;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.3px;
    text-align: center;
    color: #686868;
    .c-dialog-body{
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      padding: 30px;
      .rest_amount{
        margin-top: 16px;
        padding: 11px;
        border-radius: 6px;
        background-color: #fff6f2;
        width: 100%;
      }
      strong{
        font-size: 17px;
        font-weight: 600;
        letter-spacing: -0.34px;
        color: #f10000;
      }
    }
    .c-dialog-footer{
      .submit{
        color: #fff;
      }
    }
  }
}