@import "./src/core/assets/scss/color";

.login-page {
  overflow: hidden;
  .c-dialog-body {
    display: flex;
  }
  .app-logo{
    width: 192px !important;
    height: 74px;
    margin: 72px auto 93px;
    img{
      width: 100%;
    }
  }
  &__main {
    &__form {
      // padding-bottom: 60px;
      // margin-bottom: 180px;
      .c-form-control {
        margin-bottom: 20px;
        input {
          margin: 8px 0 0;
        }

        .c-input-wrapper .markup {
          top: 8.5px;
        }
        .c-input-wrapper {
          .password-icon {
            height: 40px;
            top: 10px;
            right: 0;
            display: flex;
            align-items: center;
            padding-right: 10px;
            padding-left: 5px;
          }
        }
      }
      .group-form-action {
        display: flex;
        justify-content: space-between;
        .form__to-page {
          button {
            font-family: Pretendard;
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: -0.3px;
            text-align: right;
            color: $colorDisabled;
            border: none;
            background: transparent;
            padding: 0;
          }
          .box-line-top {
            height: 10px;
            background: $colorDisabled;
            color: $colorDisabled;
            width: 1px;
            display: inline-block;
            margin: 0 6px;
          }
        }
      }
      .form__auto-login {
        .c-form-control {
          display: flex;
          align-items: center;
          font-family: Pretendard;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          letter-spacing: -0.3px;
          text-align: left;
          color: $black;
          margin-bottom: 0;
        }
      }
      .form__to-page {
        margin-right: 13px;
      }


    }
  }
}

.group-form-error {
  padding: 10px 15px;
  border-radius: 6px;
  border: solid 1px rgba(255, 119, 58, 0.6);
  background-color: rgba(255, 170, 132, 0.1);
  text-align: center;
  position: fixed;
  bottom: 85px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  span {
    font-family: Pretendard;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: -0.28px;
    text-align: center;
    color: #ff773a;
    white-space: pre;
  }
}

.group-form-submit {
  position: fixed;
  bottom: 16px;
  width: calc(100% - 32px);
  left: 16px;
  height: 54px;
  z-index: 999;
  button {
    background: #cecece;
    height: 100%;
    border: none;
    width: 100%;
    opacity: 0.9;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    border-radius: 6px;

  }
  pointer-events: none;
  &.active {
    pointer-events: auto;
    button {
      background-color: #ff773a;
    }
  }
}