@import "./src/core/assets/scss/color";

.faq-page {
  background:  #f6f6f6;
  padding: 0 !important;
  header {
    margin: 0 0;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
    background: $white !important;
    //position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
  }
  &__main {
    //padding-top: 64px;
    min-height: 90vh;
    background-color: #fff;
    margin-top: 10px;
    overflow-y: auto;
    &__content{
      background: #ffffff;
      .box-content {
          margin-bottom: 30px;
          &__type {
            padding: 15px 16px 14px;
            border-bottom: solid 1px #efefef;
            h4 {
              font-family: Pretendard;
              font-size: 18px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.17;
              letter-spacing: normal;
              text-align: left;
              color: #ff773a;
            }
          }
          .c-accodition {
            box-shadow: none;
            border-bottom: solid 1px #efefef;
            .c-accodition__question__icon {
              right: 16px;
            }
            .c-accodition__question__text {
              padding-right:30px;
              word-break: break-word;
            }
            &__answer {
              &.open {
                font-family: Pretendard;
                font-size: 13px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.54;
                letter-spacing: normal;
                text-align: left;
                color: #6e6e6e;
                word-break: break-word;
                img {
                  max-width: 100%;
                  margin: auto;
                  display: block;
                }
              }
            }
          }
      }

    }
  }
  .empty-main {
    background:  #f6f6f6;
    position: relative;
    .faq-page__main__content {
      position: absolute;
      top: 35%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }
  .c-empty-icon {
    .box-content {
      background:  #f6f6f6;
      .box-content__text {
        white-space: nowrap;
        margin-bottom: 0;
      }
    }

  }
}