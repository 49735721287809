.payment-page {
	background-color: #f6f6f6;
	padding: 54px 0 0 0 !important;
  header {
    background: #FFFFFF;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
		z-index: 9999;
  }
  main{
    margin-top: 10px;
    .payment-page__content {
      // overflow-y: scroll;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
      &::-webkit-scrollbar {
        width: 3px;
        height: 13px;
      }
      &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px #f3efef;
          border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 6px #c2c2c2;
      }
      .c-accodition {
        background-color: #fff;
        border: none;
        margin-bottom: 10px;
  
        &__question {
          border-bottom: 1px solid #e2e2e2;
  
          &__text {
            padding: 17px 16px;
            font-size: 16px;
            font-weight: 600;
          }
        }
  
        &__answer {
          background-color: #fff;
          margin-bottom: 0px;
          padding-bottom: 0px;
  
          &.open {
            height: auto;
          }
  
          .order-info-accodition-content {
            padding-bottom: 10px;
            .title {
              font-family: Pretendard;
              font-size: 14px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.14;
              letter-spacing: normal;
              text-align: left;
              color: #000;
  
              span {
                color: #f10000;
              }
            }
  
            .orderer-info {
              margin-bottom: 10px;
              .phone-payment{
                .c-form-control{
                  .c-input-wrapper{
                    input{
                      background-color: #eee !important;
                    }
                  }
                }
              }
              .user-name{
                .c-form-control{
                  .c-input-wrapper{
                    input{
                      // background-color: #eee !important;
                    }
                  }
                }
              }
            }
  
            .shipping-info {
              margin-bottom: 24px;
  
              .title {
                margin-bottom: 8px;
              }
  
              .company-lunch {
                padding: 10px 11px 7px 10px;
                border-radius: 6px;
                border: solid 1px #e2e2e2;
                background-color: #f8f8f8;
  
                h2 {
                  font-family: Pretendard;
                  font-size: 16px;
                  font-weight: 600;
                  font-stretch: normal;
                  font-style: normal;
                  letter-spacing: normal;
                  text-align: left;
                  color: #989898;
                  margin-bottom: 13px;
                  word-break: break-all;
                  // display: flex;
                  // justify-content: left;
                  // align-items: center;
                  span.default-address{
                    margin-left: 7px;
                    font-family: Pretendard;
                    font-size: 12px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.17;
                    letter-spacing: -0.36px;
                    text-align: left;
                    color: #3a82ff;
                  }
                }

                .address-name{
                  display: flex;
                  justify-content: space-between;
                  label {
                    font-family: Pretendard;
                    font-size: 16px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    letter-spacing: normal;
                    text-align: left;
                    color: #7d7d7d;

                    width: calc(100% - 38px);
                    padding-right: 10px;
                    word-break: break-word;
                  }
                  .btn {
                    padding: 3px 8px;
                    border-radius: 4px;
                    border: solid 1px #8b8b8b;
                    background-color: #fff;
  
                    font-size: 10px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.2;
                    letter-spacing: normal;
                    text-align: left;
                    color: #000;

                    min-width: 38px;
                    height: 22px;
                  }
                }
  
                .buyer {
                  label {
                    font-family: Pretendard;
                    font-size: 16px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    letter-spacing: normal;
                    text-align: left;
                    color: #7d7d7d;

                    width: calc(100% - 38px);
                    padding-right: 10px;
                    word-break: break-word;
                  }
                }
  
                .address {
                  font-family: Pretendard;
                  font-size: 15px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  letter-spacing: normal;
                  text-align: left;
                  color: #989898;
                  word-break: keep-all;
                  .box-content-address{
                    display: flex;
                    .title-address{
                      font-family: Pretendard;
                      font-size: 13px;
                      font-weight: 500;
                      font-stretch: normal;
                      font-style: normal;
                      letter-spacing: -0.39px;
                      text-align: left;
                      color: #c4c4c4;

                      margin-right: 10px;
                    }
                    .content-address{

                      .address-zipcode{
                        font-family: Pretendard;
                        font-size: 16px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.25;
                        letter-spacing: normal;
                        text-align: left;
                        color: #7d7d7d;
                        margin-bottom: 0px;
                        word-break: break-all;
                      }
                    }
                  }
                }
  
                .phone {
                  font-family: Pretendard;
                  font-size: 15px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  letter-spacing: normal;
                  text-align: left;
                  color: #b8b8b8;
                }
                .no-address-payment {
                  padding: 34px 0px 37px 0px ;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: column;
                  h2{
                    font-family: Pretendard;
                    font-size: 16px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.19;
                    letter-spacing: -0.48px;
                    color: #000;
                    margin-bottom: 4px;
                    padding: 0px;

                  }
                  label{
                    font-family: Pretendard;
                    font-size: 13px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    letter-spacing: -0.39px;
                    text-align: left;
                    color: #989898;
                    margin-bottom: 9px;
                  }
                  button{
                    width: 70px;
                    height: 18px;
                    border-radius: 4px;
                    border: solid 1px #8b8b8b;
                    background-color: #fff;
                    font-family: Pretendard;
                    font-size: 10px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    letter-spacing: normal;
                    color: #000;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }
                  .btn-address-setting {
                    display: flex;
                    align-items: center;
                    height: auto;
                    padding: 1px;
                    span {
                      display: block;
                    }
                  }
                }
              }
            }
  
            .order-time {
              margin-bottom: 10px;
  
              h2 {
                span {
                  color: red;
                }
  
                font-family: Pretendard;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.14;
                letter-spacing: normal;
                text-align: left;
                color: #000;
              }
  
              .date-time {
                display: flex;
                align-items: center;
                justify-content: left;
  
                // padding: 0px 10px;
                .date-time-select {
                  padding: 0px 8px;
  
                  .c-form-control {
                    .c-dropdown-wrapper {
                      .c-dropdown-input {
                        .icon {
                          right: 11px;
  
                          img {
                            width: 11px;
                            height: 6px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
  
            .delivery-request {
              h2 {
                font-family: Pretendard;
                font-size: 14px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: normal;
                text-align: left;
                color: #000;
                margin-bottom: 0px;
              }
  
              .delivery-select {
                input {
                  margin: 8px 0px 10px 0px;
                }
  
                .c-form-control {
                  .c-dropdown-wrapper {
                    .c-dropdown-input {
                      .icon {
                        right: 11px;
  
                        img {
                          width: 11px;
                          height: 6px;
                        }
                      }
                    }
                    .c-dropdown-menu{
                      .c-dropdown-menu__list{
                        ul>li{
                          text-align: left;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
  
          .order-list-accodition-content {
            .note {
              .title {
                font-family: Pretendard;
                font-size: 10px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.2;
                letter-spacing: normal;
                text-align: left;
                color: #8b8b8b;
              }
            }
  
            .list {
              .cart-item {
                .product-list-item {
                  &:last-child {
                    border-bottom: none;
                  }
                  &__product {
                    .info-product {
                      // width: calc(100% - 80px);
                    }
                    .info-product {
                      width: calc(100% - 78px)
                    }
                  }
                  &__action {
                    position: absolute;
                    right: 0;
                    top: 0;
                  }
                  .box-content {
                    width: calc(100% - 30px);
                  }
                }
              }
            }
          }
  
          .payment-method-accodition-content {
            margin-bottom: 10px;
  
            .item {
              display: flex;
              justify-content: space-between;
              align-items: center;
              flex-wrap: wrap;
              margin: 0px;
              padding: 0px;
              
              .method {
                display: flex;
                justify-content: center;
                align-items: center;
  
                width: 48%;
                background-color: #fff;
                padding: 9px 0px;
                margin-top: 10px;
                border-radius: 6px;
                border: solid 1px #e2e2e2;
  
                span {
                  font-family: Pretendard;
                  font-size: 14px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  letter-spacing: normal;
                  text-align: center;
                  color: #000;
                }
  
                &.selected {
                  background-color: #ff773a;
                  border: none;
  
                  span {
                    font-family: Pretendard;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    letter-spacing: normal;
                    text-align: center;
                    color: #fff;
                  }
                }
  
                &.m-2 {
                  margin: 0px 8px;
                }
                &.disabled{
                  pointer-events: none;
                  &:first-child{
                    background-color: #e49e7e;
                  }
                  &:last-child{
                    background-color: #eee;
                  }
                }
              }
              
            }

            .c-form-control{
              .c-input-wrapper{
                input[name="depositorName"] {
                  height: 46px;
                  &:disabled{
                    background-color: #eee !important;
                  }
                }
              }
            }
            .info-store{
              text-align: center;
              margin-top: 10px;
              .bank-name{
                font-family: Pretendard;
                font-size: 14px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.14;
                letter-spacing: normal;
                text-align: left;
                color: #000;
                margin-right: 17px;
              }
              .number-bank{
                font-family: Pretendard;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.14;
                letter-spacing: normal;
                text-align: left;
                color: #000;
              }
              .note-bank{
                margin-top: 21px;
                margin-bottom: 20px;
                border-radius: 4px;
                background-color: #ecf3ff;
                padding: 8px 0px;
                span{
                  font-family: Pretendard;
                  font-size: 12px;
                  font-weight: 500;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.17;
                  letter-spacing: -0.24px;
                  text-align: left;
                  color: #3a82ff;
                }
              }
            }
  
            .bank-select-ipt {
              padding: 10px 20px 10px 15px;
              border-radius: 6px;
              border: solid 0.5px #e2e2e2;
              background-color: #fbfbfb;
              display: flex;
              justify-content: space-between;
  
              span {
                font-family: Pretendard;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: normal;
                text-align: left;
                color: #ababab;
              }
            }
            .card-hand{
              margin-top: 30px;
              &__header{
                h3{
                  font-family: Pretendard;
                  font-size: 14px;
                  font-weight: 600;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.14;
                  letter-spacing: normal;
                  text-align: center;
                  color: #666;
                }
              }
              &__content{
                .card-hand-form{
                  .number-card{
                    label{
                      font-family: Pretendard;
                      font-size: 14px;
                      font-weight: 600;
                      font-stretch: normal;
                      font-style: normal;
                      line-height: 1.14;
                      letter-spacing: normal;
                      text-align: left;
                      color: #000;
                    }
                    &__box{
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      .number-card__input{
                        margin-right: 8px;
                        &:last-child{
                          margin-right: 0px;
                        }
                        .c-form-control{
                          .c-input-wrapper{
                            input{
                              width: 100%;
                            }
                          }
                        }
                      }
                      .locked{
                        position: relative;
                        img{
                          position: absolute;
                          top: 23px;
                          right: 10px;
                        }
                        .c-form-control{
                          .c-input-wrapper{
                            input.cart-mask{
                              -webkit-text-security: disc;
                              -moz-webkit-text-security: disc;
                              -moz-text-security: disc;
                            }
                          }
                        }
                      }

                      
                    }
                    
                  }
                  .card-info{
                    display: flex;
                    .card-expired{
                      width: 50%;
                      label{
                        font-family: Pretendard;
                        font-size: 14px;
                        font-weight: 600;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.14;
                        letter-spacing: normal;
                        text-align: left;
                        color: #000;
                      }
                      .c-form-control{
                        .c-input-wrapper{
                          input{
                            width: 100%;
                          }
                        }
                      }
                      &:first-child{
                        margin-right: 8px;
                      }
                      .input-group-expire{
                        height: 40px;
                        font-family: Pretendard;
                        font-size: 14px;
                        margin: 10px 0;
                        padding: 10px;
                        border-radius: 6px;
                        border: solid 1px #e2e2e2;
                        display: flex;
                        align-items: center;
                        input.mm{
                          height: 38px;
                          width: 25%;
                          border: none;
                          font-size: 14px;
                          -webkit-text-security: disc;
                          -moz-webkit-text-security: disc;
                          -moz-text-security: disc;
                          &::placeholder {
                            font-family: Pretendard;
                            font-size: 14px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            letter-spacing: normal;
                            text-align: left;
                            color: #9c9c9c;
                          }
                        }
                        span{
                          color: #9c9c9c;
                        }
                        input.yy{
                          padding-left: 8px;
                          height: 38px;
                          width: 70%;
                          border: none;
                          font-size: 14px;
                          -webkit-text-security: disc;
                          -moz-webkit-text-security: disc;
                          -moz-text-security: disc;
                          &::placeholder {
                            font-family: Pretendard;
                            font-size: 14px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            letter-spacing: normal;
                            text-align: left;
                            color: #9c9c9c;
                          }
                        }
                      }
                    }
                  }
                }

              }
            }
          }
        }
        &.order-list-accodition{
          padding-bottom: 0px;
          .c-accodition__question{
            padding-bottom: 10px;
            .box-content-question{
              padding: 0px;
              .c-accodition__question__title{
                padding-right: 20px;
              }
            }
            .c-accodition__question__icon {
              right: 15px;
            }
          }
          .c-accodition__answer{
            max-height: 351px;
            overflow-y: scroll;
            margin-right: 8px;
            &::-webkit-scrollbar {
              width: 2px;
              background-color: #fff;
            } 
            &::-webkit-scrollbar-thumb {
              padding-right: 2px;
              background-color: #c2c2c2;
            }
            .order-list-accodition-content{
              .list{
                .cart-item{
                  height: auto !important;
                  .product-list-item{
                    &__content{
                      .box-content{
                        .product-list-item__product{
                          width: 100%;
                          .info-product{
                            &__name{
                              word-break: break-word;
                              overflow: hidden;
                              display: -webkit-box;
                              -webkit-line-clamp: 2;
                              line-clamp: 2;
                              -webkit-box-orient: vertical;
                              margin-bottom: 17px;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              .disabled{
                .cart-item{
                  .product-list-item{
                    .cspin-number{
                      &__group{
                        .cspin{
                          .action {
                            background-color: #eee;
                            pointer-events: none;
                          }
                          .input-number {
                            background-color: #eee;
                            pointer-events: none;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        &.payment-method-accodition{
          .c-accodition__answer{
            min-height: 0px;
          }
        }
      }
  
      .total-payment-amount {
        padding: 15px 16px;
        background-color: #fff;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
        margin-bottom: 10px;
  
        .group {
          display: flex;
          justify-content: space-between;
          margin-bottom: 8px;
  
          .title {
            font-family: Pretendard;
            font-size: 18px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.17;
            letter-spacing: normal;
            text-align: left;
            color: #000;
            padding-bottom: 0px;
            margin-bottom: 0px;
          }
  
          .amount {
            font-family: Pretendard;
            font-size: 18px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.17;
            letter-spacing: normal;
            text-align: right;
            color: #000;
          }
        }
  
        .note {
          label {
            font-family: Pretendard;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.17;
            letter-spacing: -0.24px;
            text-align: left;
            color: #8b8b8b;
          }
        }
      }
  
      .privacy-policy {
        padding: 14px 16px;
        background-color: #fff;
  
        &__all {
          display: flex;
          justify-content: left;
          align-items: center;
          border-bottom: solid 1px #e2e2e2;
          padding-bottom: 10px;
          margin-bottom: 14px;
  
          .check-all {}
  
          label {
            font-family: Pretendard;
            font-size: 18px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.17;
            letter-spacing: normal;
            text-align: left;
            color: #000;
          }
        }
  
        &--product {
          display: flex;
          justify-content: left;
        }
  
        &__item {
          display: flex;
          justify-content: space-between;
          margin-bottom: 14px;
  
          .check-item {}
  
          label {
            font-family: Pretendard;
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: -0.3px;
            text-align: left;
            color: #000;
          }
  
          &:last-child {
            margin-bottom: 0px;
          }
  
          img.img-right {
            width: 7px;
            height: 12px;
          }
        }
  
        &--border-bottom {
          border-bottom: solid 1px #e2e2e2;
          padding-bottom: 14px;
        }
        &.disabled{
          .privacy-policy__all{
            pointer-events: none;
          }
          .privacy-policy__item{
            pointer-events: none;
          }
        }
      }
  
      .payment {
        padding: 16px 16px 80px 16px;
        width: 100%;
        background-color: #fff;
        &.disable{
          padding: 13px 16px 80px 16px;
          width: 100%;
          background-color: #ebebeb;
          .box-err{
            margin-bottom: 13px;
            display: flex;
            justify-content: space-between;
            .text-err{
              font-family: Pretendard;
              font-size: 10px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              letter-spacing: normal;
              text-align: left;
              color: #f10000;
            }
            .total-err{
              font-family: Pretendard;
              font-size: 10px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              letter-spacing: normal;
              text-align: right;
              color: #000;
            }
          }
        }
        
        &--accodition-close{
          height: calc(100vh - 550px);
        }
      }
    }
  }
}

.box-content-address{
  span{
    font-family: Pretendard;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.39px;
    text-align: left;
    color: #c4c4c4;
    margin-right: 10px;
  }
  font-family: Pretendard;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7d7d7d;
}

input:disabled{
  background-color: #eee !important;
  border: solid 1px #eee;
}

#overlay-payment{
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}