.order-cancel-detail-page {
    background-color: #ebebeb;
    padding: 54px 0 0 0 !important;
    header {
      background: #FFFFFF;
      position: fixed;
      top: 0px;
      left: 0px;
      right: 0px;
      z-index: 9999;
      .header-wrapper{
        .header-content{
          display: flex;
          justify-content: center;
          h2{
            font-family: Pretendard;
            font-size: 20px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: -0.4px;
            color: #000;
          }
        }
      }
    }
    main{
      .content{
        .date-cancel{
          margin-top: 10px;
          background-color: #fff;
          padding: 15px 16px;
          .date{
            display: flex;
            justify-content: space-between;
            .title-left{
              opacity: 0.5;
              font-family: Pretendard;
              font-size: 17px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.41;
              letter-spacing: normal;
              text-align: left;
              color: #000;
            }
            .title-right{
              font-family: Pretendard;
              font-size: 15px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.6;
              letter-spacing: normal;
              text-align: right;
              color: #000;
            }
          }
          .reason{
            &--inline{
              display: flex;
              justify-content: space-between;
            }
            &--block{
              // display: flex;
              // justify-content: space-between;
            }
            .title-left{
              opacity: 0.5;
              font-family: Pretendard;
              font-size: 17px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.41;
              letter-spacing: normal;
              text-align: left;
              color: #000;
            }
            .title-right{
              .reason-content{
                font-family: Pretendard;
                font-size: 15px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: normal;
                text-align: right;
                color: #9c9c9c;
                margin-right: 2px;
                word-wrap: break-word;
                white-space-collapse: break-spaces;
                word-break: break-all;
                .content-more{
                  text-align: left;
                  margin-bottom: 0;
                }
                .three-dot{
                  color: #9c9c9c;
                }
              }
              span{
                font-family: Pretendard;
                font-size: 12px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: normal;
                text-align: left;
                color: #000;
              }
            }
            
          }
        }
        .info-cancel{
          margin-top: 10px;
          background-color: #fff;

          .info-head{
            display: flex;
            justify-content: left;
            align-items: center;
            padding: 15px 16px;
            border-bottom: 1px solid #e2e2e2;
            img{
              margin-right: 10px;
            }
            h2{
              font-family: Pretendard;
              font-size: 18px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              letter-spacing: normal;
              text-align: left;
              color: #000;
              padding: 0px;
              margin: 0px;
            }
          }
          .amount{
            padding: 15px 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #e2e2e2;
            .title-left{
              font-family: Pretendard;
              font-size: 17px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.41;
              letter-spacing: normal;
              text-align: left;
              color: #ff773a;
            }
            .title-right{
              font-family: Pretendard;
              font-size: 21px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.14;
              letter-spacing: normal;
              text-align: right;
              color: #ff773a;
            }
          }
        }
        .info-payment-method{
          background-color: #f9f9f9;
          .top{
            padding: 15px 16px 0px 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .title-left{
              font-family: Pretendard;
              font-size: 15px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.6;
              letter-spacing: normal;
              text-align: left;
              color: #000;
            }
            .title-right{
              font-family: Pretendard;
              font-size: 15px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.6;
              letter-spacing: normal;
              text-align: right;
              color: #000;
            
            }
          }
          .bottom{
            text-align: right;
            padding-right: 16px;
            padding-bottom: 5px;
            span{
              font-family: Pretendard;
              font-size: 13px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.85;
              letter-spacing: normal;
              text-align: left;
              color: #9c9c9c;
              &:first-child{
                margin-right: 10px;
              }
            }
          }

        }
        .info-note{
          background-color: #ebebeb;
          padding: 15px 16px;
          .note-bgr{
            text-align: center;
            border-radius: 4px;
            background-color: #8f8f8f;
            padding: 8px 0px;
            margin-bottom: 21px;
            label{
              font-family: Pretendard;
              font-size: 13px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              letter-spacing: -0.26px;
              text-align: center;
              span{
                &:first-child{
                  color: #fff;
                }
                &:last-child{
                  color: #ffe437;
                }
              }
            }
          }
          .icon{
            display: flex;
            justify-content: left;
            align-items: center;
            img{
              margin-right: 10px;
            }
            span{
              font-family: Pretendard;
              font-size: 13px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              letter-spacing: normal;
              text-align: left;
              color: #686868;
            }
          }
          .content{
            font-family: Pretendard;
            font-size: 13px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.85;
            letter-spacing: normal;
            text-align: left;
            color: #9c9c9c;
          }
        }
        .btn-group{
          background-color: #ebebeb;
          width: 100%;
          padding: 0px 16px;
          .d-grid{
            width: 100%;
          }
        }
      }
    }
}
