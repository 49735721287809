@font-face {
    font-family: 'Pretendard';
    src: url('../fonts/Pretendard/regular/Pretendard-Regular.eot');
    src: url('../fonts/Pretendard/regular/Pretendard-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Pretendard/regular/Pretendard-Regular.woff2') format('woff2'),
    url('../fonts/Pretendard/regular/Pretendard-Regular.woff') format('woff'),
    url('../fonts/Pretendard/regular/Pretendard-Regular.ttf') format('truetype'),
    url('../fonts/Pretendard/regular/Pretendard-Regular.svg#Pretendard-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Pretendard';
    src: url('../fonts/Pretendard/semi-bold/Pretendard-SemiBold.eot');
    src: url('../fonts/Pretendard/semi-bold/Pretendard-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Pretendard/semi-bold/Pretendard-SemiBold.woff2') format('woff2'),
    url('../fonts/Pretendard/semi-bold/Pretendard-SemiBold.woff') format('woff'),
    url('../fonts/Pretendard/semi-bold/Pretendard-SemiBold.ttf') format('truetype'),
    url('../fonts/Pretendard/semi-bold/Pretendard-SemiBold.svg#Pretendard-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Pretendard';
    src: url('../fonts/Pretendard/medium/Pretendard-Medium.eot');
    src: url('../fonts/Pretendard/medium/Pretendard-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Pretendard/medium/Pretendard-Medium.woff2') format('woff2'),
    url('../fonts/Pretendard/medium/Pretendard-Medium.woff') format('woff'),
    url('../fonts/Pretendard/medium/Pretendard-Medium.ttf') format('truetype'),
    url('../fonts/Pretendard/medium/Pretendard-Medium.svg#Pretendard-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Pretendard';
    src: url('../fonts/Pretendard/bold/Pretendard-Bold.eot');
    src: url('../fonts/Pretendard/bold/Pretendard-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Pretendard/bold/Pretendard-Bold.woff2') format('woff2'),
    url('../fonts/Pretendard/bold/Pretendard-Bold.woff') format('woff'),
    url('../fonts/Pretendard/bold/Pretendard-Bold.ttf') format('truetype'),
    url('../fonts/Pretendard/bold/Pretendard-Bold.svg#Pretendard-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Pretendard';
    src: url('../fonts/Pretendard/light/Pretendard-Light.eot');
    src: url('../fonts/Pretendard/light/Pretendard-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Pretendard/light/Pretendard-Light.woff2') format('woff2'),
    url('../fonts/Pretendard/light/Pretendard-Light.woff') format('woff'),
    url('../fonts/Pretendard/light/Pretendard-Light.ttf') format('truetype'),
    url('../fonts/Pretendard/light/Pretendard-Light.svg#Pretendard-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SBAggroM';
    src: url('../fonts/SBAggroM/SBAggroM.ttf');
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat/Montserrat-Regular.ttf');
}