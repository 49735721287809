@import "color";

.c-accodition {

    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
    &:first-child {
        border-top: 2px solid rgba(0, 0, 0, 0.06);
    }
    &__question {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        flex-wrap: wrap;

        &__text {
            //width: calc(100% - 15px);
            font-family: Pretendard;
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: normal;
            text-align: left;
            color: #000;
            margin-bottom: 0;
            padding: 16px;
        }
        &__icon {
            //padding-right: 16px;
            position: absolute;
            top: 20px;
            right: 16px;
            &.show {
                transform: rotate(180deg);
                padding: 0;
                //margin-right: 16px;
            }
        }
        &__title {
            font-family: Pretendard;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: normal;
            text-align: right;
            color: #000;
            margin-right: 23px;
            margin-bottom: 0;
            &--update{
                width: calc(100% - 107px);
                label{
                    display: flex;
                    align-items: unset;
                    width: 100%;
                    justify-content: flex-end;

                    line-height: 1.14;
                    span.product-name{
                        width: calc(100% - 90px);
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        font-family: Pretendard;
                        font-size: 14px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        letter-spacing: normal;
                        color: #000;
                        margin-right: 3px;
                    }
                }
            }
        }
        &__description {
            padding: 0 16px;
            font-family: Pretendard;
            font-size: 10px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            text-align: left;
            color: #8b8b8b;
        }
    }
    &__answer {
        min-height: 0;
        overflow: hidden;
        -webkit-transition: min-height 0.15s;
        -moz-transition: min-height 0.15s;
        -ms-transition: min-height 0.15s;
        -o-transition: min-height 0.15s;
        transition: min-height 0.15s;
        &.open {
            margin-top: 0;
            padding: 14px 16px;
            min-height: 127px;
            height: auto;
            background-color: #fbfbfb;
        }
    }
    &.accodition-type {
        padding-bottom: 12px;
        &.open {
            padding-bottom: 0;
            .c-accodition__question {
                border-bottom: 1px solid  #e2e2e2;
                padding-bottom: 12px;
            }
        }
        .c-accodition__question {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            flex-wrap: wrap;
            .box-content-question {
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: relative;
                flex-wrap: wrap;
                width: 100%;
                margin-bottom: 3px;
                padding: 16px 16px 0;
            }
            &__text {
                //width: calc(100% - 15px);
                font-family: Pretendard;
                font-size: 14px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.14;
                letter-spacing: normal;
                text-align: left;
                color: #000;
                margin-bottom: 0;
                padding: 0;
            }
            &__icon {
                //padding-right: 16px;
                position: absolute;
                top: 23px;
                right: 0px;
                &.show {
                    transform: rotate(180deg);
                    padding: 0;
                    //margin-right: 16px;
                }
            }
            &__title {
                font-family: Pretendard;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.43;
                letter-spacing: normal;
                text-align: right;
                color: #000;
                margin-right: 14px;
                margin-bottom: 0;
            }
            &__description {
                padding: 0 16px;
                font-family: Pretendard;
                font-size: 10px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.2;
                letter-spacing: normal;
                text-align: left;
                color: #8b8b8b;
            }
        }
        .c-accodition__answer.open {
            //margin-top: 12px;
            background-color: $white;
        }
    }
}


.rc-accordion-toggle {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    justify-content: space-between;
    background-color: #ebedf0;
    transition: 0.3s;
}
.rc-accordion-toggle.active{
    background-color: #5a8dee;
}
.rc-accordion-toggle.active .rc-accordion-icon{
    transform: rotate(180deg);
    color: #fff;
}
.rc-accordion-card{
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 10px;
    overflow: hidden;
}
.rc-accordion-card:last-child{
    margin-bottom: 0;
}
.rc-accordion-title{
    font-weight: 500;
    font-size: 14px;
    position: relative;
    margin-bottom: 0;
    color: #475F7B;
    transition: 0.3s;
}
.rc-accordion-toggle.active .rc-accordion-title{
    color: #fff;
}
.rc-accordion-icon{
    position: relative;
    top: 2px;
    color: #475F7B;
    transition: 0.35s;
    font-size: 12px;
}
.rc-accordion-body{
    flex: 1 1 auto;
    min-height: 1px;
    padding: 15px;
}
.rc-accordion-body p{
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #727E8C;
}
.rc-collapse{
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
}
.rc-collapse.show{
    height: auto;
}