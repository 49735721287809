.cspin-number{
  padding: 10px 16px;
  &__group{
    .title{
      font-family: Pretendard;
      font-size: pxToVW(14);
      font-weight: $bold;
      text-align: left;
      color: $colorBlack;
      margin-bottom: 5px;
    }
    .cspin{
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 4px;
      padding-right: 4px;
      .action{
        width: pxToVW(49);
        height: pxToVW(50);
        border-radius: pxToVW(6);
        background-color: #ebebeb;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 24px;
      }
      .input-number{
        margin: 0 pxToVW(10);
        border-radius: pxToVW(6);
        border: solid 1px #e2e2e2;
        width: 100%;
        height: pxToVW(50);
        text-align: right;
        padding: pxToVW(13) pxToVW(22);
        display: flex;
        align-items: center;
        justify-content: flex-end;
        &.error{
          border: solid 1px #f10000;
        }
        .number{
          font-family: Pretendard;
          font-size: pxToVW(20);
          font-weight: 600;
          color: #000;
        }
      }
    }
  }
  .error-exceed-quantity{
    margin-top: 20px;
    border-radius: 10px;
    border: solid 1px #fff2ce;
    background-color: #fffdf9;
    padding: 13px 0px;
    text-align: center;
    label{
      font-family: Pretendard;
      font-size: 13px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.31;
      letter-spacing: normal;
      text-align: center;
      color: #313131;
    }
  }
}