.cart-list-page {
	background-color: #f6f6f6;
	padding: 54px 0 0 0 !important;
  header {
    background: #FFFFFF;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
		z-index: 9999;
  }
	main {
    margin-top: 10px;
    background: #FFFFFF;
    padding: 0;
		.cart-list-page__content{
			padding: 0;
			box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
			background-color: #fff;
			.cart-total{
				padding: 16px;
				border-bottom: 1px solid #e2e2e2;
	
				&__group{
					display: flex;
					justify-content: space-between;
					align-items: center;
					.gr-left{
						display: flex;
						justify-content: space-between;
						align-items: center;
						label{
							font-family: Pretendard;
							font-size: 16px;
							font-weight: 600;
							font-stretch: normal;
							font-style: normal;
							line-height: 1.19;
							letter-spacing: normal;
							text-align: left;
							color: #000;
						}
					}
					.gr-right{
						label{
							font-family: Pretendard;
							font-size: 15px;
							font-weight: normal;
							font-stretch: normal;
							font-style: normal;
							line-height: 1.2;
							letter-spacing: normal;
							text-align: right;
							color: #3f3c3c;
							&.disabled{
								color: #8b8b8b;
							}
						}
					}
				}
			}
			.cart-item{
				padding: 16px;
				// height: calc(100vh - 160px);
				// max-height: 100vh;
				overflow-y: scroll;
				padding: 0px 16px 130px 16px;

				&--loading{
					margin-top: 22px;
					.item-loading{
						display: flex;
						.left-loading{
							width: 100%;
							margin-left: 6px;
							.react-loading-skeleton{
								margin-bottom: 25px;
							}
						}
					}
				}
			}
		}
		.cart-list-page__total{
			padding: 16px;
			position: fixed;
			background-color: #fff;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			padding: 16px;
			border-top: 10px solid #ebebeb;
			box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
			
			.cart-payment-total{
				&__info{
					label{
						font-family: Pretendard;
						font-size: 12px;
						font-weight: normal;
						font-stretch: normal;
						font-style: normal;
						line-height: 1.17;
						letter-spacing: normal;
						text-align: left;
						color: #8b8b8b;
					}
					.payment-total{
						display: flex;
						justify-content: space-between;
						align-items: center;
						margin: 3px 0 12px 0;
						.title{
							font-family: Pretendard;
							font-size: 18px;
							font-weight: 600;
							font-stretch: normal;
							font-style: normal;
							line-height: 1.17;
							letter-spacing: normal;
							text-align: left;
							color: #000;
						}
						.total{
							font-family: Pretendard;
							font-size: 18px;
							font-weight: 600;
							font-stretch: normal;
							font-style: normal;
							line-height: 1.17;
							letter-spacing: normal;
							text-align: right;
							color: #000;
						
						}
					}
				}
			}
		}
	}
	.no-cart{
		height: calc(100vh - 64px);
		background-color: #f6f6f6;
		position: relative;
		&__content{
			margin: 0;
			position: absolute;
			top: 50%;
			left: 50%;
			margin-right: -50%;
			transform: translate(-50%, -50%);
			.cart-list-empty{
			}
			.d-grid{
				height: auto !important;
				padding: 18px 105px;
				.cbtn{
					height: 44px;
					padding: 0px 30px;
				}
			}
		}
	}
	.product-list-item {
		&__product {
			width: 100%;
			.info-product {
				width: calc(100% - 78px);
				&__name{
					word-break: break-word;
					overflow: hidden;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					line-clamp: 2;
					-webkit-box-orient: vertical;

					margin-bottom: 17px;
				}
			}
		}
		&__action {
			margin-left: 0px;
			position: absolute;
			right: 0;
			top: 0;
		}
		.box-content {
			display: flex;
			width: calc(100% - 29px);
		}
	}
}

@media screen and (max-width: 320px) {
	.cart-list-page {
		.product-list-item {
			&__content {
				.cspin-number {
					.cspin-number__group {
						.cspin {
							.input-number {
								width: 32px;
								padding: 11px 5px;
							}
						}
					}
				}
			}
		}
	}
}