@import "./src/core/assets/scss/color";

.my-info-page {
  background:  #ebebeb;
  padding: 0 !important;
  overflow-y: hidden;
  header {
    margin: 0 0;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
    background: $white;
    // position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    .header-wrapper .header-content {
      align-items: center;
    }
  }
  &__main {
    background: $white;
    min-height: calc(100vh - 148px);
    position: relative;
    overflow-y: auto;
    margin-top: 10px;
    .box-list-panel {
      overflow-y: auto;
      background:  #ebebeb;
    }
    .box-line {
      width: 100%;
      height: 10px;
      background-color: #ebebeb;
      position: relative;
      &::before {
        content: "";
        display: block;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.06);
        height: 2px;
        opacity: .6;
      }
    }
    &__info-member {
      padding: 16px;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
      .box-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
        &__data {
          &__phone {
            font-family: Pretendard;
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: -1px;
            text-align: left;
            color: #ff773a;
          }
          &__gender {
            font-family: Pretendard;
            font-size: 20px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            text-align: left;
            color: #000;
            margin-left: 3px;
          }
          &__icon {
            display: inline-block;
            vertical-align: unset;
            margin-left: 3px;
          }
        }
        &__action {
          &__icon {
            margin-left: 16px;
          }
        }
      }
      .box-btn-action {
        padding: 0;
        border: solid 1px #000;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        &__icon {
          width: calc(100%/2);
          padding: 10px;
          img {
            display: block;
            margin: auto;
            //width: 14px;
            height: 48px;
            object-fit: contain;
          }
          &:nth-child(1){
            border-bottom: 2px solid #e2e2e2;
          }
          &:nth-child(2){
            border-bottom: 2px solid #e2e2e2;
          }
          &:nth-child(2n){
            border-left: 2px solid #e2e2e2;
          }
        }
      }
    }
    .d-grid-fixed-bottom {
      position: absolute;
    }
    &__info-order {
      padding: 16px;
      border-bottom: solid 1px #e2e2e2;
      background-color: white;
      .info-order__title {
        font-family: Pretendard;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.19;
        letter-spacing: normal;
        text-align: left;
        color: #000;
        margin-bottom: 10px;
      }
      .info-order__status {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        padding: 21px 24px 18px 16px;
        border-radius: 10px;
        border: solid 1px #000;
        background-color: rgba(255, 255, 255, 0.8);
        &__item {
          text-align: center;
        }
        img {
          margin-bottom: 11px;
        }
        p {
          font-family: Pretendard;
          font-size: 14px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.14;
          letter-spacing: -0.28px;
          text-align: center;
          color: #000;
          min-height: 31px;
          align-items: center;
          display: flex;
        }
        strong {
          font-family: Pretendard;
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.19;
          letter-spacing: -0.32px;
          text-align: center;
          color: #ff0046;
        }
      }
    }
    &__info-menu {
      //padding-bottom: 21px;
      background-color: white;
      .c-list-item {
        border-top:none !important;
        border-bottom:none !important;
        &:nth-child(2){
          border-bottom: 1px solid rgba(0, 0, 0, 0.06) !important;
        }
        &:last-child{
          border-bottom: 1px solid rgba(0, 0, 0, 0.06) !important;
        }
      }
    }
    &__info-btn {
      margin-bottom: 15px;
      padding: 21px 16px;
      box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.06);
      background-color: white;
    }
  }

}