@import "color";

.tabs{
    padding: 0;
    margin: 0;
    &__heading{
        display: flex;
        &__list{
            display: flex;
            padding: 0;
            flex-direction: row;
            flex-wrap: nowrap;
            align-content: stretch;
            justify-content: space-evenly;
            align-items: center;
            width: 100%;
            margin: 0;
            border-bottom: 1px solid $borderDisabled;
        }
        &__item{
            list-style: none;
            display: flex;
            width: 100%;
            align-items: flex-end;
            align-content: center;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: center;
            box-sizing: border-box;
            font-family: Pretendard;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.19;
            letter-spacing: normal;
            text-align: center;
            color: $colorDisabled;
            padding: 15px 0 11px;
            position: relative;
            &.active{
                font-family: Pretendard;
                font-size: 16px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.19;
                letter-spacing: normal;
                text-align: center;
                color: $black;
                &::before {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 2px;
                    bottom: 0px;
                    background-color: $orange;
                    position: absolute;
                }
            }

        }
        .badge-dot-noti{
            position: relative;
        }
    }
    &__content{
        &__section{
            display: none;
            padding: 30px 25px;
            div{
                word-break: break-all;
            }
            &.active{
                display: block;
            }
        }
    }
}