@import 'responsive.scss';
@import "fonts";
@import "function";
body, html{
  background: #FFFFFF;
  font-family: 'Pretendard', serif;
  //overscroll-behavior-y: none;
  overscroll-behavior-x: none;
  @include respond-to($sm) {
    display:none
  }
}

*:focus {
  outline: none;
}

.loading-overlay {
  position: absolute;
  inset: 0 0 0 0;
  background: #00000024;
  padding: calc(50vh - 60px) 10px;
  text-align: center;
  z-index: 9999999;
  svg{
    height: 120px !important;
  }
}

.app-layout{
  box-sizing: border-box;
  min-height: 100vh;
  padding: 0 16px;
  &:has(#bottom_navigation){
    padding-bottom: 90px;
  }
  .app-logo{
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.breakpoint{
  color: transparent;
}

#debugPanel{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: red;
  color: yellow;
}


.react-datepicker__header {
  text-align: center;
  background-color: #f6773b;
  border-bottom: 0px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
  div{
    color: #FFFFFF;
  }
}
.react-datepicker__current-month{
  font-size: 14px;
}
.react-datepicker-popper, .react-datepicker__month-container{
  width: 280px !important;
  padding-bottom: 16px;
}
.react-datepicker__day-name, .react-datepicker__day{
  width: 32px !important;
  height: 32px;
  line-height: 32px;
  font-size: 12px;
}
.react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled {
  //cursor: default;
  //color: #fff;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  border-radius: 50%;
  background-color: #fca17b;
  color: #fff;
}
.react-datepicker{
  border: 0;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #ff773a;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  border-top: none;
  border-bottom-color: #ff773a;
}

.react-datepicker__navigation {
  align-items: center;
  background: none;
  display: flex;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 8px;
  padding: 0;
  border: none;
  z-index: 1;
  height: 32px;
  width: 32px;
  text-indent: -999em;
  overflow: hidden;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
  border-color: #ff773a;
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: "";
  display: block;
  height: 9px;
  position: absolute;
  top: 6px;
  width: 9px;
}
.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover {
  background-color: #ff773a;
}
.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
  border-radius: 50%;
  background-color: #ff773a;
}

@import "bottom_navigation";
@import "form";
@import "header";
@import "float_button";
@import "switcher";
@import "empty_icon";
@import "accodition";

@import "list_item";
@import "tabs";
@import "dialog";
@import "product_list_item";
@import "toast";

.modal-disconnect-internet {
  .c-dialog-body {
    display: flex;
    justify-content: center;
    span {
      font-family: Pretendard;
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: -0.3px;
      text-align: center;
      color: #686868;
    }
  }
}

@supports (-webkit-overflow-scrolling: touch) {
  /* CSS specific to iOS devices */
  input[type="password" i] {
    //font-size: 6px !important;
    font: small-caption;
    font-family: Verdana !important;
  }
}

.app-version-dialog{
  .c-dialog-body{
    display: flex;
    justify-content: center;
    align-items: center;
    .content-app-version{
      font-family: Pretendard;
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: -0.3px;
      text-align: center;
      color: #686868;
    }
  }
  
}

.fancybox__container{
  z-index: 9999;
  .fancybox__toolbar{
    top: 20px
  }
}