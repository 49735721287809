@import "../../../../core/assets/scss/color";

.edit-profile-page {
  background:  #ebebeb;
  padding: 0 !important;
  header {
    margin: 0 0;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
    background: $white;
  }
  &__main {
    //margin-top: 10px;
    background: $white;
    //min-height: calc(100vh - 60px);
    //margin-top: 10px;
    overflow-y: auto;
    height: 100%;
    &__title {
      margin-bottom: 24px;
      padding: 22px 16px 0;
      label {
        font-family: Pretendard;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.19;
        letter-spacing: normal;
        text-align: left;
        color: #000;
      }
    }
    &__form {
      padding: 0px 16px;
      border-bottom: 1px solid #e2e2e2;
      .c-form-control {
        margin-bottom: 25px;
        input {
          margin: 8px 0 0 !important;
          &:disabled {
            background: #eeeeee;
        }
        }
        .c-form-error {
          margin-top: 10px;
          margin-bottom: 0;
        }
      }
      .c-form-control-referrral {
        .c-form-control {
          margin-bottom: 20px;
        }
      }
    }
    &__add-contact {
      padding: 20px 16px;
      padding-bottom: 115px;
      .box-add-contact {
        margin-bottom: 80px;
        &__title {
          display: flex;
          justify-content: space-between;
          &__text {
            font-family: Pretendard;
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: normal;
            text-align: left;
            color: #000;
          }
        }
        &__note {
          font-family: Pretendard;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.14;
          letter-spacing: normal;
          text-align: left;
          color: #000;
          margin-bottom: 10px;
        }
        &__warning {
          font-family: Pretendard;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.17;
          letter-spacing: normal;
          text-align: left;
          color: #ff1c1c;
        }
        &__list {
          margin-top: 24px;
          &__item {
            padding: 11px 10.5px 11px 14px;
            border-radius: 6px;
            border: solid 1px #e2e2e2;
            background-color: #fff;
            margin-bottom: 10px;

            .add-contact-item {
              position: relative;
              &__close {
                position: absolute;
                right: 0;
                top: -2px;
              }
            }

          }
        }
      }
    }

    &__submit {
      position: fixed;
      bottom: 0;
      width: 100%;
      left: 0;
      z-index: 800;
      padding: 16px;
      background-color: $white;
      .box-submit {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
      button {
        display: inline-block;
        width: calc(100%/2 - 5px);
        align-items: center;
        padding: 18px 18px 17px 18px;
        border-radius: 6px;
        white-space: nowrap;
        border: none;
        font-family: Pretendard;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.19;
        letter-spacing: normal;
        text-align: center;
        box-shadow:none !important;
        &.btn-default {
          background-color: #ebebeb;
          @supports (-webkit-overflow-scrolling: touch) {
            /* CSS specific to iOS devices */
            color: #000;
          }
        }
        &.btn-primary {
          font-weight: 600;
        }
      }
    }
  }

}