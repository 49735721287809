@import "color";
.product-item {
    &__quantity {
        //position: absolute;
        display: flex;
        right: 0;
        bottom: 0;
        align-items: center;
        &__action {
            display: flex;
            align-items: center;
        }
        button {
            height: 22px;
            width: 22px;
            border-radius: 6px;
            background-color: #ebebeb;
            text-align: center;
            border: none;
            display: flex;
            align-items: center;
            padding: 0;
            justify-content: center;
        }
        input {
            width: 52px;
            height: 32px;
            margin: 0 5px;
            font-family: Pretendard;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.17;
            letter-spacing: normal;
            text-align: right;
            color: #000;
            border-radius: 6px;
            border: solid 1px $borderDisabled;
        }
    }
}
.product-list-item {
    border-bottom: 1px solid $borderDisabled;
    padding: 22px 0;
    &__content {
        display: flex;
        justify-content: space-between;
        position: relative;
        .cspin-number {
            padding: 0;
            position: absolute;
            display: flex;
            right: 0;
            bottom: 0;
            align-items: center;
        }
        .cspin-number__group {
            .cspin {
                .action {
                    height: 22px;
                    width: 22px;
                    border-radius: 6px;
                    background-color: #ebebeb;
                    text-align: center;
                    padding: 0;
                    img {
                        width: 7px;
                        height: 7px;
                    }
                }
                .input-number {
                    width: 52px;
                    height: 32px;
                    margin: 0 5px;
                    font-family: Pretendard;
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.17;
                    letter-spacing: normal;
                    text-align: right;
                    color: #000;
                    border-radius: 6px;
                    border: solid 1px #e2e2e2;
                    padding: 11px 10px;
                    .number {
                        font-family: Pretendard;
                        font-size: 12px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.17;
                        letter-spacing: normal;
                        text-align: right;
                        color: #000;
                        width: 100%;
                    }
                }
            }
        }
        .featured-product{
            position: absolute;
            top: 6px;
            left: 6px;
            img{
                width: 38px;
                height: 38px;
                object-fit: contain;
            }
        }
        
    }
    .product-item {
        &__quantity {
            position: absolute;
        }
    }
    .box-content {
        display: flex;
    }
    &__checkbox {

    }
    &__product {
        display: flex;
        position: relative;
        .image-product {
            
            width: 72px;
            height: 72px;
            border-radius: 6px;
            border: solid 0.5px #e2e2e2;
            background-color: #e8e8e8;
            margin-right: 6px;
            overflow: hidden;
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
            
        }
        .featured-product-cart{
            position: absolute;
            top: 4px;
            left: 4px;
            img{
                width: 20px;
                height: 20px;
                object-fit: contain;
            }
        }
        .info-product {
            position: relative;
            &__name {
                display: block;
                width: 100%;
                font-family: Pretendard;
                font-size: 16px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.19;
                letter-spacing: normal;
                text-align: left;
                color: $black;
                margin-bottom: 29px;
                word-break: break-word;
            }
            &__price {
                display: block;
                width: 100%;
                font-family: Pretendard;
                font-size: 16px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.19;
                letter-spacing: normal;
                text-align: left;
                color: $black;
                position: absolute;
                left: 0;
                bottom: 0;
            }
        }

    }
    &__action {
        margin-left: 5px;
        &__delete {
            background: transparent;
            border: none;
            font-family: Pretendard;
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            text-align: right;
            color: $colorGrey;
            padding: 0;
            white-space: nowrap;
        }
        &__quantity {
            position: absolute;
            display: flex;
            right: 0;
            bottom: 0;
            align-items: center;
            button {
                height: 22px;
                width: 22px;
                border-radius: 6px;
                background-color: #ebebeb;
                text-align: center;
            }
            input {
                width: 52px;
                height: 32px;
                margin: 0 5px;
                font-family: Pretendard;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.17;
                letter-spacing: normal;
                text-align: right;
                color: #000;
                border-radius: 6px;
                border: solid 1px $borderDisabled;
            }
        }
    }
    &.type-big {
        border: none;
        margin-bottom: 24px;
        padding: 0;
        .product-list-item__product {
            display: block;
            .image-product {
                width: 100%;
                height: 152px;
                border-radius: 6px;
                border: solid 0.5px #e2e2e2;
                background-color: #e8e8e8;
                margin-right: 0px;
                margin-bottom: 10px;
                min-width: 157px;
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
            .info-product {

                &__name {
                    display: block;
                    width: 100%;
                    font-family: Pretendard;
                    font-size: 14px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.43;
                    letter-spacing: normal;
                    text-align: left;
                    color: $black;
                    margin-bottom: 4px;
                    word-break: break-word;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2; /* number of lines to show */
                            line-clamp: 2; 
                    -webkit-box-orient: vertical;
                }
                &__price {
                    display: block;
                    font-family: Pretendard;
                    font-size: 13px;
                    font-weight: 800;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.15;
                    letter-spacing: normal;
                    text-align: left;
                    color: #ff0046;
                    position:static;
                }
            }
        }
    }
}
.list-product-order {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    .product-list-item {
        width: calc(100%/2 - 14px);
        &:nth-child(2n){
            margin-right: 0;
        }
    }
}