.good-detail-page{
	background-color: #f6f6f6;
	padding: 54px 0 0 0 !important;
  header {
    background: #FFFFFF;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
		z-index: 9999;
  }
  &__content{
    margin-top: 10px;
    overflow-y: scroll;
    margin-right: 3px;
    &::-webkit-scrollbar {
      width: 3px;
      height: 13px;
    }
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px #f3efef;
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px #c2c2c2;
    }

    .good-info{
      background-color: #fff;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
      padding: 16px 16px 20px 16px;
      .img-swiper{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ffe9df;
        border-radius: 6px;
        padding: 20px 25px;
        position: relative;

        .carousel-root{
          width: 100%;
          .carousel-slider{
            position: unset;
          }
          .slider-wrapper{
              width: 100%;
              overflow: visible;
              .slider{
                .slide{
                  div{
                    width: 100%;
                    height: 200px;
                    img{
                      width: 100%;
                      height: 100%;          
                      border-radius: 12px;
                      object-fit: cover;
                    }
                  }
                }
              }
          }
          .carousel-status{
              top: 5px;
              right: 7px;
              text-shadow: unset;
              opacity: 0.7;
              font-family: Pretendard;
              font-size: 13px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.15;
              letter-spacing: normal;
              text-align: left;
              color: #000;
          }
          .slide{
              padding: 12px 4px;
          }
        }
      }
      .featured-product-detail{
        position: absolute;
        bottom: -38px;
        right: 9px;
      }
      .good-info-detail{
        padding-top: 20px;
        padding-bottom: 9px;
        &__title{
          font-family: Pretendard;
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.11;
          letter-spacing: normal;
          text-align: left;
          color: #000;
          word-break: break-word;
        }
        .space-name{
          padding-right: 80px;
        }
        &__description{
          font-family: Pretendard;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: normal;
          text-align: left;
          color: #8b8b8b;
          margin-bottom: 20px;
          word-break: break-all;
        }
        &__price{
          font-family: Pretendard;
          font-size: 16px;
          font-weight: 800;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.19;
          letter-spacing: normal;
          text-align: left;
          color: #ff0046;
        }
      }
    }
    .good-tab{
      background-color: #fff;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
      margin-top: 15px;

      padding-bottom: 70px;

      // height: 100vh;
      // padding-bottom: 30px;
      .tabs__content {
        img {
          max-width: 100%;
        }
      }
    }
    .good-order{
      position: fixed;
      width: 100%;
      bottom: 0px;
      background-color: #fff;
      padding: 0px 16px;
      padding-bottom: 30px;
      z-index: 9999;
      .d-grid{
        background-color: #fff;
        button{
          box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.15);
        }
      }
    }
  }
}
.order-info{
  padding: 8px 16px 20px 16px;
  border-bottom: 1px solid #e2e2e2;
  &__title{
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    color: #000;
  }
  &__item{
    .city{
      height: 18px;
      border-radius: 4px;
      background-color: #fa0;
      padding: 0px 8px;
      margin-right: 4px;
      margin-bottom: 4px;
      span{
        font-family: Pretendard;
        font-size: 10px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
      }
      &--5{
        background-color: #fa0;
      }
      &--20{
        background-color: #9e8eff;
      }
      &--30{
        background-color: #21c789;
      }
    }
    .rate{
      font-family: Pretendard;
      font-size: 12px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: left;
      color: #8b8b8b;
      .add{
        font-weight: 400;
      }
    }
  }
}
.payment-amount{
  padding: 20px 16px;
  &__group{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .title{
      font-family: Pretendard;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.19;
      letter-spacing: normal;
      text-align: left;
      color: #000;
    }
    .amount{
      font-family: Pretendard;
      font-size: 22px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.18;
      letter-spacing: normal;
      text-align: right;
      color: #ff0046;
    }
  }
  &__button{
    .btn{
      padding: 18px;
      border: none;
      border-radius: 6px;
      width: calc(50% - 5px);

      font-family: Pretendard;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.19;
      letter-spacing: normal;
      text-align: center;
      color: #000;
      &--shopping-cart{
        background-color: #ebebeb;
        margin-right: 5px;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.19;
        letter-spacing: normal;
        text-align: center;
        color: #000;
        &:focus{
          background-color: #ebebeb !important;
          color: #000 !important;
        }
        &:active{
          background-color: #ebebeb !important;
          color: #000 !important;
        }
      }
      &--order{
        margin-left: 5px;
        background-color: #ff773a;

        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.19;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
        &:focus{
          background-color: #ff773a;
          color: #fff;
        }
        &.disabled{
          background-color: #cecece;
          color: #ffffff;
        }
      }
      &:disabled{
        background-color: #ebebeb;
        color:#0000007d;

      }
    }
  }
}

.modal-dialog-no-address{
  .c-dialog-body{
    display: flex;
    justify-content: center;
    align-items: center;
    .content-dialog{
      font-family: Pretendard;
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: -0.3px;
      color: #686868;
      text-align: center;
      // display: flex;
      // justify-content: center;
      // align-items: center;
    }
  }
  &.msg-cart-verify{
    text-align: left;
    white-space: break-spaces;
  }
}
.tabs-main{
  .tabs__content{
    &__section{
      .pages-nav-branch{
        img{
          width: 100%;
        }
      }
    }
  }
}