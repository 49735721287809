@import "./src/core/assets/scss/color";

.setting-push-page {
  background:  #f6f6f6;
  padding: 0 !important;
  header {
    margin: 0 0;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
    background: $white;
  }
  &__main {
    margin-top: 10px;
    background: $white;
    min-height: 90vh;
    &.empty-main {
      background:  #f6f6f6;
    }
    &__content{

      .box-content {
        padding: 14px 20px;
          &__action {
            margin-bottom: 30px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            label {
              font-family: Pretendard;
              font-size: 16px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.19;
              letter-spacing: normal;
              text-align: left;
              color: #000;
            }
          }
          &__description {
            p {
              font-family: Pretendard;
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.43;
              letter-spacing: normal;
              text-align: left;
              color: #8b8b8b;
              margin-bottom: 20px;
            }
          }
      }

    }
  }

}