.policy-page{
  padding: 0px;
  .icon-header{
    padding: 15px 0px;
    background: #fff;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.06);
    margin: 0 -16px;
    padding: 15px 16px;
    position: fixed;
    width: 100%;
    z-index: 999;
  }
  .policy-content{
    height: calc(100vh - 54px);
    // display: flex;
    justify-content: center;
    align-items: center;
    // padding: 20px;
    padding-bottom: 30px;
    padding-top: 69px;
    div{
        color: #000;
        font-family: Pretendard;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -.28px;
        line-height: 1.14;
        text-align: left;
        font-size: 14px;
        font-weight: 400;
        padding-bottom: 30px;
        p {
          font-size: 16px;
          // font-weight: 700;
          
        }
        label {
          // padding-left: 10px;
          display: block;
        }
    }
  }
}