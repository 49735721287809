.order-history-list-page {
  background-color: #ebebeb;
  padding: 0px !important;
  .c-empty-icon .box-content__description {
    font-size: 16px;
  }
  &__custom-header {
    position: fixed;
    width: 100%;
    z-index: 9999;
    background-color: #fff;
  }
  &__time-filter{
    position: fixed;
    top: 53px;
    padding: 0px 16px 0px 16px;
    background-color: #fff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
    margin-bottom: 10px;
    z-index: 9999;
    width: 100%;
    .time-setting{
      margin: 0px;
      padding: 0px;
      overflow-x: scroll;
      padding: 16px 0px;
      margin: 0px;
      display: flex;
      &__item{
        border-radius: 6px;
        width: 72px;
        height: 27px;
        padding: 3px 0px;
        margin-right: 13px;
        border: solid 1px #444970;
        background-color: #fff;
        list-style-type: none;
        text-align: center;
        span{
          font-family: Pretendard;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: center;
          color: #444970;
        }
        &--active{
          background-color: #444970;
          border: none;
          span{
            color: #fff;
          }
        }
      }
    }
  }
  &__content{
    padding-top: 123px;
    overflow-y: scroll;
    .list{
      background-color: #fff;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
      padding: 16px;
      margin-bottom: 10px;
      &__header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 12px;
        border-bottom: solid 1px #e2e2e2;
        margin-bottom: 16px;
        &__left{
          .date-time{
            font-family: Pretendard;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.19;
            letter-spacing: normal;
            text-align: left;
            color: #000;
          }
        }
        &__right{
          display: flex;
          justify-content: space-between;
          align-items: center;
          .order-detail-text{
            font-family: Pretendard;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: normal;
            text-align: right;
            color: #8b8b8b;
            margin-right: 4px;
          }
          img{
            width: 6.6px;
            height: 11.9px;
          }

        }
      }
      &__body{
        .item{
          margin-bottom: 12px;
          display: flex;
          align-items: center;
          &__left{
            width: 64px;
          }
          &__right{
            &--update-product{
              width: calc(100% - 60px);
            }
          }
          .title{
            opacity: 0.7;
            font-family: Pretendard;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.19;
            letter-spacing: -0.48px;
            text-align: left;
            color: #000;
          }
          .content{
            font-family: Pretendard;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.19;
            letter-spacing: -0.48px;
            text-align: left;
            color: #000;
            &--update-product{
              
              display: flex;
              align-items: unset;
              width: 100%;
              justify-content: flex-start;
              line-height: 1.14;
              .product-name{
                max-width: calc(100% - 70px);
                text-align: right;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                margin-right: 3px;
              }
            }
          }
          .amount{
            font-family: Pretendard;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.19;
            letter-spacing: -0.48px;
            text-align: left;
            color: #000;
          }
          &--status-order{
            align-items: center;
          }
        }
      }
      &__bottom{
        .shopping-cart-btn{
          margin-bottom: 6px;
          .d-grid{
            border-radius: 6px;
            border: solid 1px #ff773a;
            button{
              height: 44px;
              padding: 0px;
              
            }
            
          }
        }
        .item{
          display: flex;
          justify-content: space-between;
          .d-grid{
            border-radius: 6px;
            border: solid 1px #ff773a;
            button{
              background-color: #fff;
              font-family: Pretendard;
              font-size: 16px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.19;
              letter-spacing: normal;
              text-align: center;
              color: #ff773a;
              height: 44px;
              padding: 0px;
            }
            &--disabled{
              border: solid 1px #e2e2e2;
              background-color: #e2e2e2;
              button{
                opacity: 0.8;
                font-family: Pretendard;
                font-size: 16px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.19;
                letter-spacing: normal;
                text-align: center;
                color: #8b8b8b;
              }
            }
          }
          &__left{
            padding-right: 2px;
          }
          &__right{
            padding-left: 2px;
          }
        }
      }
      &:last-child{
        margin-bottom: 0px;
      }
    }
    &--loading{
      background-color: #fff;
      padding: 16px;
      &__item{
        div{
          margin-bottom: 10px;
          label{
            width: 64px;
            opacity: 0.7;
            font-family: Pretendard;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.19;
            letter-spacing: -0.48px;
            text-align: left;
            color: #000;
          }
        }
      }
      .list__bottom{
        .shopping-cart-btn{
          .d-grid{
            border: solid 1px #e2e2e2;
            button{
              border: solid 1px #e2e2e2;
            }
          }
        }
        .d-grid{
          &--disabled{
            border: solid 1px #e2e2e2;
            background-color: #e2e2e2;
            button{
              opacity: 0.8;
              font-family: Pretendard;
              font-size: 16px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.19;
              letter-spacing: normal;
              text-align: center;
              color: #8b8b8b;
            }
          }
        }
      }
    }
  }
  &--empty{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .order-history-empty{
    height: calc(100vh - 160px);
    display: flex;
    justify-content: space-around;
    /* justify-content: flex-end; */
    align-items: center;
    .c-empty-icon  {
      margin-bottom: 9px;
    }
    .d-grid {
      justify-content: center;
      .cbtn {
        padding: 13px 32px 12px 31px;
      }
    }
  }
}

.bottom-groupt{
  .shopping-cart-btn{
    margin-bottom: 6px;
    .d-grid{
      border-radius: 6px;
      border: solid 1px #ff773a;
      button{
        height: 44px;
        padding: 0px;
        
      }
      
    }
  }
  .item{
    display: flex;
    justify-content: space-between;
    .d-grid{
      border-radius: 6px;
      border: solid 1px #ff773a;
      button{
        background-color: #fff;
        font-family: Pretendard;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.19;
        letter-spacing: normal;
        text-align: center;
        color: #ff773a;
        height: 44px;
        padding: 0px;
      }
      &--disabled{
        border: solid 1px #e2e2e2;
        background-color: #e2e2e2;
        button{
          opacity: 0.8;
          font-family: Pretendard;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.19;
          letter-spacing: normal;
          text-align: center;
          color: #8b8b8b;
        }
      }
    }
    &__left{
      padding-right: 2px;
    }
    &__right{
      padding-left: 2px;
    }
  }
}
.button-status{
  border-radius: 4px;
  border: solid 1px #ffb300;
  background-color: #fff;
  padding: 3px 10px;
  span{
    font-family: Pretendard;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #ffb300;
  }
  &--waiting-deposit{
    border: solid 1px #ffb300;
    span{
      color: #ffb300;
    }
  }
  &--cod-payment{
    border: solid 1px #3ccb37;
    span{
      color: #3ccb37;
    }
  }
  &--waiting-for-payment{
    border: solid 1px #21c789;
    span{
      color: #21c789;
    }
  }

  &--payment-failed{
    border: solid 1px #acacac;
    background-color: #acacac;
    span{
      color: #fff;
    }
  }

  &--waiting-for-order{
    border: solid 1px #ec3b59;
    span{
      color: #ec3b59;
    }
  }

  &--withdraw-order{
    border: solid 1px #6b3600;
    span{
      color: #6b3600;
    }
  }

  &--complete-payment{
    border: solid 1px #ec3b59;
    span{
      color: #ec3b59;
    }
  }
  &--canceled{
    border: solid 1px #6b3600;
    span{
      color: #6b3600;
    }
  }
  &--canceled-completed{
    border: solid 1px #af68ff;
    span{
      color: #af68ff;
    }
  }
  
  &--refund-complete{
    border: solid 1px #5061ff;
    span{
      color: #5061ff;
    }
  }
  &--order-reception{
    border: solid 1px #5c1fef;
    span{
      color: #5c1fef;
    }
  }
  &--delivery-completed{
    border: solid 1px #35a1e9;
    span{
      color: #35a1e9;
    }
  }
  &--delivery-starts{
    border: solid 1px #ff48f9;
    span{
      color: #ff48f9;
    }
  }
  &--ready-for-delivery{
    border: solid 1px #9b9b9b;
    span{
      color: #9b9b9b;
    }
  }
}