.address-page{
    padding: 64px 0 0 0 !important;
    background-color: #f6f6f6;

    header {
        background: #FFFFFF;
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        z-index: 9999;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
    }

    main {
        // overflow-y: scroll;
        padding-bottom: 95px;
        .list {
            overflow-y: scroll;
            padding-bottom: 95px;
        }
    }
    .list {
        overflow-y: scroll;
        padding-bottom: 95px;
    }
    .bottom-btn{
        position: fixed;
        z-index: 9999;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 16px;
        background: #FFF;
        button{
            box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.15);

        }
    }

    .c-empty-icon{
        margin-top: 154px;
    }

    .delete-dialog{
        text-align: center;
        font-family: Pretendard;
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: -0.3px;
        color: #686868;
        .c-dialog-body {
            padding: 0 30px 0px 30px;
            min-height: 110px;
            display: flex;
            align-items: center;
            justify-content: center;
            span{
                display: inline;
            }
        }
    }

    .address-item{
        padding: 16px;
        background: #FFFFFF;
        margin-bottom: 1px;
        font-family: Pretendard;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.75;
        letter-spacing: normal;
        color: #8b8b8b;
        h3{
            display: inline-block;
            font-family: Pretendard;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            line-height: 1.7;
            text-align: left;
            color: #000;
        }
        p{
            font-family: Pretendard;
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            text-align: left;
            color: #000;
            margin-bottom: unset;
        }
        .default-address {
            font-family: Pretendard;
            font-size: 10px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            text-align: left;
            color: #ff773a;
            padding: 3px 8px;
            border-radius: 4px;
            border: solid 1px #ff773a;
            background-color: #fff;
            margin-left: 5px;
        }
    }

    form{
        // margin-top: 10px;
        background: #FFF;
        padding: 16px;
        padding-bottom: 16px;
        // margin-bottom: 146px;
    }

    .address-search{
        z-index: 9999999999;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: #FFFFFF;
        padding: 16px;
    }
    .form-addrees {
        input {
            &:disabled {
                background-color: #eeeeee;
            }
        }
        .group-input-address {
            button {
                margin-bottom: 0;
                padding: 0 9px;
                white-space: nowrap;
                width: auto;
            }
            .form-control-address {
                width: calc(100% - 102px);
            }
            input {
                margin-bottom: 0;
            }
        }
        .form-control-address-detail-kakao {
            input {
                margin-bottom: 0;
            }
        }
    }
}
@media screen and (max-height: 570px) {
    .address-page{
        form {
            margin-bottom: 106px;
        }
    }
}

@media screen and (min-height: 600px) and (max-height: 620px) {
    /* Your styles go here */
    .address-page{
        form {
            margin-bottom: 56px;
        }
    }
}