@import "./src/core/assets/scss/color";

.annoucement-page {
  background:  #f6f6f6;
  padding: 0 !important;
  header {
    margin: 0 0;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
    background: $white;
    margin: 0 0;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
    background: #ffffff;
    //position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
  }
  &__main {
    margin-top: 10px;
    background: $white;
    min-height: 90vh;
    //padding-top: 64px;
    background-color: #f6f6f6;
    overflow-y: auto;
    .box-list-item-annoucement{
      background: $white;
    }
    &.empty-main {
      background:  #f6f6f6;
    }
    &__content{

      .c-list-item {
        &__content {
          padding-top: 24px;
          align-items: inherit;
          .item-title {
            max-width: calc(100% - 5px);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          &__icon {
            margin-bottom: 15px;
          }
        }
      }
      .content-detail {
        //padding: 0 19px 0 17px;
        .content-title {
          padding: 20px 19px 10px 17px;
          border-bottom: solid 1px #efefef;
          margin-bottom: 20px;
          &__text{
            font-family: Pretendard;
            font-size: 18px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.17;
            letter-spacing: normal;
            text-align: left;
            color: $black;
            word-break: break-word;
          }
          &__time {
            font-family: Pretendard;
            font-size: 11px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.18;
            letter-spacing: normal;
            text-align: right;
            color: #c9c7c7;
            width: 100%;
            display: block;
          }
        }
        .content-description {
          padding: 0 19px 0 17px;
          margin-bottom: 86px;
          p {
            font-family: Pretendard;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.8;
            letter-spacing: normal;
            text-align: left;
            color: #8b8b8b;
            white-space: pre-wrap;
            word-break: break-word;
            margin-bottom: 5px;
          }
          img {
            width: 100%;
            max-height: 330px;
            object-fit: cover;
          }
        }
      }

    }
  }
  .c-empty-icon {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .box-content {
      &__text{
        white-space: nowrap;
        font-weight:500;
      }
    }
  }
  .c-list-item__content__text .item-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .c-list-item__content__icon  {
    margin-bottom: 15px;
  }
  .empty-data {
    background-color: #ebebeb !important;
  }
}
.annoucement-detail-page {
  .annoucement-page__main {
    background-color: #ffffff;
    min-height: auto;
    &.empty-main {
      background: #f6f6f6;
    }
  }
  .d-grid-fixed-bottom {
    width: 100%;
    left: 0;
    bottom: 0;
    margin: 20px;
  }
  .content-description {
    img {
      max-width: 100%;
      margin-bottom: 5px;
    }
    span , p, label {
        line-height: 1.2;
    }
  }
}