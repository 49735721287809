@import "color";

.c-switcher {
    position: relative;
    display: inline-block;
    height: 23px;
    width: 41px;
    input {
        opacity: 0;
        width: 0;
        height: 0;
        &:checked + .slider {
            background-color: $orange;
        }
        &:checked + .slider:before {
            -webkit-transform: translateX(16px);
            -ms-transform: translateX(16px);
            transform: translateX(16px);
        }
    }
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $colorDisabled;
        -webkit-transition: .4s;
        transition: .4s;
        width: 41px;
        &:before {
            position: absolute;
            content: "";
            height: 17px;
            width: 17px;
            left: 4px;
            bottom: 3px;
            background-color: $white;
            -webkit-transition: .6s;
            transition: .6s;
        }
        &.round {
            border-radius: 34px;
            &:before {
                border-radius: 50%;
            }
        }
    }
}