$bgPrimary: #ff773a;
$colorPrimary: #ff773a;
$borderPrimary: #ff773a;
$bgDisabled: #cecece;
$borderDisabled: #e2e2e2;
$colorDisabled: #8b8b8b;
$white:#ffffff;
$black:#000000;
$orange:#ff773a;
$gray:#f6f6f6;
$colorBlack: #000000;
$bold: 700;
$medium: 500;
$normal: 400;
$colorGrey: #8b8b8b;
$viewPort: 360

