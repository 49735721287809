.order-detail-page {
  background-color: #f6f6f6;
  padding: 0px !important;

  &__custom-header {
    position: fixed;
    width: 100%;
    z-index: 9999;
    background-color: #fff;
  }
  &__content{
    padding-top: 64px;
    .order-info-box{
      background-color: #fff;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
      padding: 16px 0px;
      margin-bottom: 10px;
      &__header{
        padding: 0px 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
        &__left{
          .date-time{
            font-family: Pretendard;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.19;
            letter-spacing: normal;
            text-align: left;
            color: #000;
          }
        }
        &__right{
          display: flex;
          justify-content: space-between;
          align-items: center;
          .order-detail-text{
            font-family: Pretendard;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: normal;
            text-align: right;
            color: #8b8b8b;
            margin-right: 4px;
          }
          img{
            width: 6.6px;
            height: 11.9px;
          }
        }
      }
      &__body{
        .info{
          padding: 0px 16px;
          border-bottom: solid 1px #e2e2e2;
          .item{
            margin-bottom: 12px;
            display: flex;
            &__left{
              min-width: 94px;
            }
            &__right{
              &--update-product{
                width: calc(100% - 80px);
              }
            }
            .title{
              opacity: 0.7;
              font-family: Pretendard;
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.19;
              letter-spacing: -0.48px;
              text-align: left;
              color: #000;
            }
            .content{
              font-family: Pretendard;
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.19;
              letter-spacing: -0.48px;
              text-align: left;
              color: #000;
              &--update-product{
              
                display: flex;
                align-items: unset;
                width: 100%;
                justify-content: flex-start;
                line-height: 1.14;
                .product-name{
                  max-width: calc(100% - 75px);
                  text-align: right;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  margin-right: 3px;
                  text-align: left;
                }
              }
            }
            .amount{
              font-family: Pretendard;
              font-size: 16px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.19;
              letter-spacing: -0.48px;
              text-align: left;
              color: #000;
            }
            &--status-order{
              align-items: center;
            }
          }
        }
        .product{
          padding: 0px 16px;
          padding-top: 10px;
          max-height: 940px;
          overflow-y: scroll;
          margin-right: 8px;
          &::-webkit-scrollbar {
            width: 2px;
            background-color: #fff;
          } 
          &::-webkit-scrollbar-thumb {
            height: 100px;
            background-color: #c2c2c2;
          }
          .title{
            font-family: Pretendard;
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.19;
            letter-spacing: normal;
            text-align: left;
            color: #000;
            padding-bottom: 3px;
            margin-bottom: 0px;
            span{
              opacity: 1;
              font-family: Pretendard;
              font-size: 16px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.19;
              letter-spacing: normal;
              text-align: left;
              color: #000;
            }
          }
          .item{
            padding: 16px 0px;
            border-bottom: solid 1px #e2e2e2;
            .title{
              font-family: Pretendard;
              font-size: 16px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.19;
              letter-spacing: normal;
              text-align: left;
              color: #000;
              margin-bottom: 19px;
              word-wrap: break-word;
              span{
                opacity: 1;
                font-family: Pretendard;
                font-size: 16px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.19;
                letter-spacing: normal;
                text-align: left;
                color: #000;
              }
            }
            .product-price{
              font-family: Pretendard;
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.19;
              letter-spacing: -0.16px;
              text-align: left;
              color: #000;
            }
            &:last-child{
              border-bottom: none;
            }
          }
          span{
            opacity: 0.7;
            font-family: Pretendard;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: normal;
            text-align: left;
            color: #000;
            margin-bottom: 10px;
          }
          .amount{
            margin-top: 10px;
            font-family: Pretendard;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.19;
            letter-spacing: -0.16px;
            text-align: left;
            color: #000;
          }
        }
      }
      &__bottom{
        padding: 0px 16px;
        .shopping-cart-btn{
          margin-bottom: 6px;
          .d-grid{
            border-radius: 6px;
            border: solid 1px #ff773a;
            button{
              height: 44px;
              padding: 0px;
              
            }
            
          }
        }
        .item{
          display: flex;
          justify-content: space-between;
          .d-grid{
            border-radius: 6px;
            border: solid 1px #ff773a;
            button{
              background-color: #fff;
              font-family: Pretendard;
              font-size: 16px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.19;
              letter-spacing: normal;
              text-align: center;
              color: #ff773a;
              height: 44px;
              padding: 0px;
            }
            &--disabled{
              border: solid 1px #e2e2e2;
              background-color: #e2e2e2;
              button{
                opacity: 0.8;
                font-family: Pretendard;
                font-size: 16px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.19;
                letter-spacing: normal;
                text-align: center;
                color: #8b8b8b;
              }
            }
          }
          &__left{
            padding-right: 2px;
          }
          &__right{
            padding-left: 2px;
          }
        }
      }
      .btn-groups{
        padding: 0px 16px;
      }
    }
    .payment-info-box{
      background-color: #fff;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
      padding: 16px;
      margin-bottom: 10px;
      &__header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
        &__left{
          .date-time{
            font-family: Pretendard;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.19;
            letter-spacing: normal;
            text-align: left;
            color: #000;
          }
        }
        &__right{
          display: flex;
          justify-content: space-between;
          align-items: center;
          .order-detail-text{
            font-family: Pretendard;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: normal;
            text-align: right;
            color: #8b8b8b;
            margin-right: 4px;
          }
          img{
            width: 6.6px;
            height: 11.9px;
          }
        }
      }
      &__body{
        .info{
          .item{
            margin-bottom: 12px;
            display: flex;
            &__left{
              width: 64px;
            }
            .title{
              opacity: 0.7;
              font-family: Pretendard;
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.19;
              letter-spacing: -0.48px;
              text-align: left;
              color: #000;
            }
            .content{
              font-family: Pretendard;
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.19;
              letter-spacing: -0.48px;
              text-align: left;
              color: #000;
            }
            .amount{
              font-family: Pretendard;
              font-size: 16px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.19;
              letter-spacing: -0.48px;
              text-align: left;
              color: #000;
            }
            &--status-order{
              align-items: center;
            }
            &--method{
              margin-bottom: 12px;
            }
            &--bank{
              .item__left{
                .title{
                  opacity: 0.7;
                  font-family: Pretendard;
                  font-size: 16px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.14;
                  letter-spacing: normal;
                  text-align: left;
                  color: #000;
                  padding-bottom: 0px;
                  margin-bottom: 0px;
                }
              }
              .item__right{
                &--card{
                  .content{
                    opacity: 0.7;
                    font-family: Pretendard;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    letter-spacing: normal;
                    text-align: left;
                    color: #000;
                  }
                }
              }
            }
            .space{
              padding: 0px 5px;
            }
          }
        }
      }
    }
    .shipping-address-box{
      background-color: #fff;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
      padding: 16px;
      margin-bottom: 10px;
      &__header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
        &__left{
          .date-time{
            font-family: Pretendard;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.19;
            letter-spacing: normal;
            text-align: left;
            color: #000;
          }
        }
        &__right{
          display: flex;
          justify-content: space-between;
          align-items: center;
          .order-detail-text{
            font-family: Pretendard;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: normal;
            text-align: right;
            color: #8b8b8b;
            margin-right: 4px;
          }
          img{
            width: 6.6px;
            height: 11.9px;
          }
        }
      }
      &__body{
        .info{
          .item{
            margin-bottom: 12px;
            display: flex;
            &__left{
              margin-right: 23px;
            }
            .title{
              opacity: 0.7;
              font-family: Pretendard;
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.19;
              letter-spacing: -0.48px;
              text-align: left;
              color: #000;
            }
            .content{
              font-family: Pretendard;
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.19;
              letter-spacing: -0.48px;
              text-align: left;
              color: #000;
            }
            .amount{
              font-family: Pretendard;
              font-size: 16px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.19;
              letter-spacing: -0.48px;
              text-align: left;
              color: #000;
            }
            &--status-order{
              align-items: center;
            }
            &--method{
              margin-bottom: 6px;
            }
            &--bank{
              .item__left{
                .title{
                  opacity: 0.7;
                  font-family: Pretendard;
                  font-size: 16px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.14;
                  letter-spacing: normal;
                  text-align: left;
                  color: #000;
                  padding-bottom: 0px;
                  margin-bottom: 0px;
                }
              }
              
            }
            .space{
              padding: 0px 5px;
            }
            &__right{
              .address-detail{
                .title-detail{
                  font-family: Pretendard;
                  font-size: 16px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.25;
                  letter-spacing: -0.48px;
                  text-align: left;
                  color: #000;
                  &:first-child{
                    margin-bottom: 5px;
                  }
                }
              }
            }
            &--address{
              .item__left{
                min-width: 30px;
              }
            }
            &.address-default{
              .item__left{
                margin-right: 12px;
                .title.bold{
                  font-family: Pretendard;
                  font-size: 16px;
                  font-weight: 600;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.19;
                  letter-spacing: normal;
                  text-align: left;
                  color: #4c4c4c;
                }
              }
              .item__right{
                .content{
                  font-family: Pretendard;
                  font-size: 12px;
                  font-weight: 500;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.17;
                  letter-spacing: -0.36px;
                  text-align: left;
                  color: #3a82ff;
                }
              }
            }
          }
        }
      }
    }
    .delivery-request-box{
      background-color: #fff;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
      padding: 16px;
      &__header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
        &__left{
          .date-time{
            font-family: Pretendard;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.19;
            letter-spacing: normal;
            text-align: left;
            color: #000;
          }
        }
        &__right{
          display: flex;
          justify-content: space-between;
          align-items: center;
          .order-detail-text{
            font-family: Pretendard;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: normal;
            text-align: right;
            color: #8b8b8b;
            margin-right: 4px;
          }
          img{
            width: 6.6px;
            height: 11.9px;
          }
        }
      }
      &__body{
        .info{
          .note-detail{
            .title{
              font-family: Pretendard;
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.19;
              letter-spacing: -0.48px;
              text-align: left;
              color: #000;
              padding-bottom: 0px;
              margin-bottom: 0px;
              &:first-child{
                margin-bottom: 5px;
              }
            }
          }
        }
      }
    }
    &--loading{
      background-color: #fff;
      padding: 16px;
      &__item{
        div{
          margin-bottom: 10px;
          label{
            width: 100px;
            opacity: 0.7;
            font-family: Pretendard;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.19;
            letter-spacing: -0.48px;
            text-align: left;
            color: #000;
          }
        }
      }
      .list__bottom{
        .shopping-cart-btn{
          margin-bottom: 10px;
          .d-grid{
            border-radius: 5px;
            border: solid 1px #e2e2e2;
            button{
              border: solid 1px #e2e2e2;
            }
          }
        }
        .d-grid{
          border-radius: 5px;
          &--disabled{
            border: solid 1px #e2e2e2;
            background-color: #e2e2e2;
            button{
              opacity: 0.8;
              font-family: Pretendard;
              font-size: 16px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.19;
              letter-spacing: normal;
              text-align: center;
              color: #8b8b8b;

              background-color: #e2e2e2;
            }
          }
        }
      }
    }
  }
}